import { useEffect, useState, useRef } from "react";
import Header from "../../Components/Header";
import { useNavigate, useParams } from "react-router-dom";
import ApiUsers from "../../Global/Api/users.api";
import { useDispatch, useSelector } from "react-redux";
import Popup from "../../Components/Popup";
import Input from "../../UI/Input";
import useInputHandler from "../../Global/Hooks/inputHandler";
import Button from "../../UI/Button";
import { CustomSelect } from "../../UI/Select";
import ApiServiceTraderCredentials from "../../Global/Api/serviceTraderCredentials.api";
import moment from "moment";
import ApiCurrencies from "../../Global/Api/currencies.api";
import { NavLink } from "react-router-dom";
import CryptoWalletsApi from "../../Global/Api/cryptoWallets.api";
import { setCryptoWalletBillingEmpty } from "../../Store/Slice/unitradeSlice";
import TradersDataApi from "../../Global/Api/tradersData.api";
import ApiMerchantWallets from "../../Global/Api/merchantWallets.api";
import { Link } from "react-router-dom";

import "./index.scss";

export default function UserDetails() {
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  const ApiTradersData = TradersDataApi();
  const merchantWalletApi = ApiMerchantWallets();
  const dispatch = useDispatch();
  const userApi = ApiUsers();
  const parameters = useParams();
  const currenciesApi = ApiCurrencies();
  const apiCryptoWallets = CryptoWalletsApi();
  const serviceCredentialsApi = ApiServiceTraderCredentials();
  const navigate = useNavigate();
  const [generateFaModal, setGenerateFaModal] = useState(false);
  const [editDataModal, setEditDataModal] = useState(false);
  const [editPasswordModal, setEditPasswordModal] = useState(false);
  const [currencySelect, setСurrencySelect] = useState(null);
  const [createWalletModal, setCreateWalletModal] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [deleteWalletModal, setDeleteWalletModal] = useState(false);
  const [wallet, setWallet] = useState(null);
  const firsLoad = useRef(false);
  const newFaKey = useInputHandler("");
  const repeatNewFaKey = useInputHandler("");
  const ownerFaKey = useInputHandler("");
  const login = useInputHandler("");
  const serviceUrl = useInputHandler("");
  const newPassword = useInputHandler("");
  const repeatNewPassword = useInputHandler("");
  const [selectAuthType, setSelectAuthType] = useState(null);
  const [isOnline, setIsOnline] = useState();
  const [addModal, setAddModal] = useState(false);
  const [addBalanceModal, setAddBalanceModal] = useState(false);
  const [addFrozenModal, setAddFrozenModal] = useState(false);
  const [addWebWallet, setAddWebWallet] = useState(false);
  const [subModal, setSubModal] = useState(false);
  const [subBalanceModal, setSubBalanceModal] = useState(false);
  const [subFrozenModal, setSubFrozenModal] = useState(false);
  const [createCryptoWalletModal, setCreateCryptoWalletModal] = useState(false);
  const [addNumber, setAddNumber] = useState(null);
  const [addBalanceNumber, setAddBalanceNumber] = useState(null);
  const [addFrozenNumber, setAddFrozenNumber] = useState(null);
  const [subNumber, setSubNumber] = useState(null);
  const [subBalanceNumber, setSubBalanceNumber] = useState(null);
  const [subFrozenNumber, setSubFrozenNumber] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [addWalletModal, setAddWalletModal] = useState(false);
  const cryptoAddress = useInputHandler("");
  const dailyInputLimit = useInputHandler("");
  const dailyOutputLimit = useInputHandler("");
  const inputLimit = useInputHandler("");
  const subInputLimit = useInputHandler("");
  const bonusInputFee = useInputHandler("");
  const bonusOutputFee = useInputHandler("");
  const outputLimit = useInputHandler("");
  const defaultMinAmountAds = useInputHandler("");
  const subOutputLimit = useInputHandler("");
  const [rangSelect, setRangSelect] = useState(null);
  const [prioritySelect, setPrioritySelect] = useState(null);
  const [isDeposit, setIsDeposit] = useState();
  const cryptoKey = useInputHandler("");
  const limitCount = 200;
  const lastCreated = useRef(null);
  const inputFee = useInputHandler("");
  const outputFee = useInputHandler("");
  const [addLimitModal, setAddLimitModal] = useState(false);
  const [subLimitModal, setSubLimitModal] = useState(false);
  const [addLimitOutputModal, setAddLimitOutputModal] = useState(false);
  const [subLimitOutputModal, setSubLimitOutputModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [infoWallet, setInfoWallet] = useState(null);
  const balance = useInputHandler("");
  const inputFeeMerchant = useInputHandler("");
  const outputFeeMerchant = useInputHandler("");
  const bonusInputFeeMerchant = useInputHandler("");
  const bonusOutputFeeMerchant = useInputHandler("");
  const [currencySelectMerchant, setCurrencySelectMerchant] = useState(null);
  const [selectMerchant, setSelectMerchant] = useState(null);
  const limitCountAgent = 200;
  const lastDateTimeAgent = useRef(null);
  const typeData = [
    {
      value: "web3",
      label: "WEB3",
    },
    {
      value: "order",
      label: "Order",
    },
    {
      value: "fee",
      label: "Fee",
    },
  ];

  const authType = [
    {
      label: "JWT",
      value: "JWT",
    },
  ];
  const rangData = [
    {
      label: "A",
      value: "A",
    },
    {
      label: "B",
      value: "B",
    },
    {
      label: "C",
      value: "C",
    },
    {
      label: "AB",
      value: "AB",
    },
    {
      label: "BC",
      value: "BC",
    },
    {
      label: "ABC",
      value: "ABC",
    },
  ];

  const priorityData = [
    {
      label: "A",
      value: "A",
    },
    {
      label: "B",
      value: "B",
    },
    {
      label: "C",
      value: "C",
    },
    {
      label: "D",
      value: "D",
    },
    {
      label: "E",
      value: "E",
    },
  ];
  const {
    user,
    faKeyData,
    serviceTraderCredentials,
    serviceTraderWallets,
    cryptoWalletBilling,
    usersByAgent,
    merchantWallet,
    currencies,
    cryptoWallet,
    cryptoWalletStatistics,
    usersMerchant,
  } = useSelector((state) => state.rootReducer.unitradeSlice);

  useEffect(() => {
    userApi.getDataUser(parameters?.id, setIsOnline);
    currenciesApi.getData();
  }, []);

  useEffect(() => {
    if (faKeyData !== null) {
      newFaKey.setChange(faKeyData?.fa_key);
      repeatNewFaKey.setChange(faKeyData?.fa_key);
    }
  }, [faKeyData]);

  useEffect(() => {
    const formattedParams = formatParams();
    if (firsLoad.current === true) {
      apiCryptoWallets.getCryptoWalletsBilling(formattedParams);
    }
    firsLoad.current = true;
  }, [selectedTransaction]);

  const formatParams = () => {
    const params = new URLSearchParams();
    params.append("limit", limitCount);
    params.append("wallet_id", parameters?.id);
    if (selectedTransaction !== null)
      params.append("transaction_type", selectedTransaction.value);
    if (lastCreated.current !== null)
      params.append("last_created_at", lastCreated.current);
    return params;
  };

  const formatParamsAgent = () => {
    const params = new URLSearchParams();
    params.append("limit", limitCountAgent);
    params.append("agent_id", parameters?.id);
    params.append("pagination_order", "end");
    if (lastDateTimeAgent.current !== null)
      params.append("last_created_at", lastDateTimeAgent.current);
    return params;
  };

  const openGenerateFaKeyModal = () => {
    setGenerateFaModal(true);
  };

  const openEditDataModal = () => {
    login.setChange(serviceTraderCredentials?.login);
    serviceUrl.setChange(serviceTraderCredentials?.service_url);
    setSelectAuthType(
      authType?.filter(
        (el) => el?.value === serviceTraderCredentials?.type_auth
      )
    );
    setEditDataModal(true);
  };

  const openEditPasswordModal = (user) => {
    setEditPasswordModal(true);
  };

  const closeGenerateFaKeyModal = () => {
    setGenerateFaModal(false);
    newFaKey.setChange("");
    repeatNewFaKey.setChange("");
    ownerFaKey.setChange("");
  };

  const closeEditDataModal = () => {
    setEditDataModal(false);
    login.setChange("");
    serviceUrl.setChange("");
    setSelectAuthType(null);
  };

  const closeEditPasswordModal = () => {
    setEditPasswordModal(false);
    newPassword.setChange("");
    repeatNewPassword.setChange("");
  };

  const generateKey = () => {
    userApi.generateFaKey();
  };

  const sendData = () => {
    const body = {
      id: user?.id,
      new_fa_key: newFaKey.value,
      new_fa_key_again: repeatNewFaKey.value,
      owner_password: ownerFaKey.value,
    };
    userApi.editFaKeyUser(body, closeGenerateFaKeyModal);
  };

  const sendEditData = () => {
    console.log(selectAuthType);
    const body = {
      object_id: serviceTraderCredentials.id,
      login: login.value,
      service_url: serviceUrl.value,
      type_auth: selectAuthType.value ?? selectAuthType[0].value,
    };
    serviceCredentialsApi.editData(body, closeEditDataModal);
  };

  const sendEditPassword = () => {
    const body = {
      object_id: serviceTraderCredentials.id,
      new_password: newPassword.value,
      new_password_again: repeatNewPassword.value,
    };
    serviceCredentialsApi.editPassword(body, closeEditPasswordModal);
  };

  const openCreateWalletModal = () => {
    setCreateWalletModal(true);
  };

  const closeCreateWalletModal = () => {
    setCreateWalletModal(false);
    setСurrencySelect(null);
  };

  const createWallet = () => {
    const body = {
      user_id: parameters?.id,
      currency_id: currencySelect.value,
      merchant_id: selectMerchant.value,
    };
    serviceCredentialsApi.createWallet(body, closeCreateWalletModal);
  };

  const openDeleteWalletModal = (wallet) => {
    setWallet(wallet);
    setDeleteWalletModal(true);
  };

  const closeDeleteWalletModal = () => {
    setWallet(null);
    setDeleteWalletModal(false);
  };

  const renderWallets = () => {
    return serviceTraderWallets?.map((item) => (
      <tr key={item?.id}>
        <td>
          <Link target="_blank" to={`/user/${item?.merchant?.id}`}>
            {item?.merchant?.notice
              ? item?.merchant?.notice
              : item?.merchant?.id}
          </Link>
        </td>
        <td>{item?.currency?.currency}</td>
        <td>{item?.balance}</td>
        <td>{item?.deposit}</td>
        <td>{item?.frozen_balance}</td>
        <td>
          {moment.utc(item?.created_at).local().format("DD.MM.YY, HH:mm:ss")}
        </td>
        <td className="users__actions">
          <div>
            {item?.updated_at
              ? moment
                  .utc(item?.updated_at)
                  .local()
                  .format("DD.MM.YY, HH:mm:ss")
              : "--- --- ---"}
          </div>
          <div className="users__btns">
            <NavLink
              to={`/service-trader/wallet/${item?.id}`}
              className="user__btn"
            >
              <img src="/assets/icons/eye-icon.svg" alt="" />
            </NavLink>
            <div
              onClick={() => {
                openDeleteWalletModal(item);
              }}
              className="users__btn"
            >
              <img src="/assets/icons/trash-ad.svg" alt="" />
            </div>
          </div>
        </td>
      </tr>
    ));
  };

  const deleteSendData = () => {
    const body = {
      wallet_id: wallet?.id,
    };
    serviceCredentialsApi.deleteWallet(body, closeDeleteWalletModal);
  };

  const addDeposit = () => {
    const body = {
      wallet_id: parameters?.id,
      deposit: addNumber,
    };
    apiCryptoWallets.addDepositToWallet(body);
    setAddModal(false);
  };

  const addBalance = () => {
    const body = {
      wallet_id: parameters?.id,
      balance: addBalanceNumber,
    };
    apiCryptoWallets.addBalanceToWallet(body);
    setAddBalanceModal(false);
  };

  const addFrozenBalance = () => {
    const body = {
      wallet_id: parameters?.id,
      frozen_balance: addFrozenNumber,
    };
    apiCryptoWallets.addFrozenToWallet(body);
    setAddFrozenModal(false);
  };

  const subDeposit = () => {
    const body = {
      wallet_id: parameters?.id,
      deposit: subNumber,
    };
    apiCryptoWallets.substractDepositFromWallet(body);
    setSubModal(false);
  };

  const subBalance = () => {
    const body = {
      wallet_id: parameters?.id,
      balance: subBalanceNumber,
    };
    apiCryptoWallets.substractBalanceFromWallet(body);
    setSubBalanceModal(false);
  };

  const subFrozen = () => {
    const body = {
      wallet_id: parameters?.id,
      frozen_balance: subFrozenNumber,
    };
    apiCryptoWallets.substractFrozenFromWallet(body);
    setSubFrozenModal(false);
  };

  const openAddModal = () => {
    setAddModal(true);
  };

  const openAddBalanceModal = () => {
    setAddBalanceModal(true);
  };

  const openAddFrozenModal = () => {
    setAddFrozenModal(true);
  };

  const openCreateCryptoWalletModal = () => {
    setCreateCryptoWalletModal(true);
  };

  const closeCreateCryptoWalletModal = () => {
    setCreateCryptoWalletModal(false);
  };

  const closeAddModal = () => {
    setAddModal(false);
  };

  const closeAddBalanceModal = () => {
    setAddBalanceModal(false);
  };

  const closeAddFrozenModal = () => {
    setAddFrozenModal(false);
  };

  const openSubModal = () => {
    setSubModal(true);
  };

  const openSubBalanceModal = () => {
    setSubBalanceModal(true);
  };

  const openSubFrozenModal = () => {
    setSubFrozenModal(true);
  };

  const closeSubModal = () => {
    setSubModal(false);
  };

  const closeSubBalanceModal = () => {
    setSubBalanceModal(false);
  };

  const closeSubFrozenModal = () => {
    setSubFrozenModal(false);
  };
  const createCryptoWallet = () => {
    const body = {
      wallet_id: parameters?.id,
    };
    apiCryptoWallets.changeCryptoWallet(body, closeCreateCryptoWalletModal);
  };

  const loadMore = () => {
    lastCreated.current =
      cryptoWalletBilling[cryptoWalletBilling.length - 1]?.created_at;
    const formattedParams = formatParams();
    apiCryptoWallets.getCryptoWalletsBilling(formattedParams);
  };

  const loadMoreAgent = () => {
    lastDateTimeAgent.current =
      usersByAgent[usersByAgent.length - 1]?.created_at;
    const formattedParams = formatParamsAgent();
    userApi.getUsersByAgent(formattedParams);
  };

  const renderBilling = () => {
    return cryptoWalletBilling?.map((billing) => (
      <tr key={billing?.id}>
        <td>
          <NavLink
            onClick={() => {
              localStorage.setItem("order_id", billing?.order_id);
            }}
            to={`/order/${billing?.order_id}`}
            className="user__btn"
          >
            {billing?.order_id}
          </NavLink>
        </td>
        <td>{billing?.currency_type}</td>
        <td>{billing?.operation_type}</td>
        <td>{billing?.transaction_type}</td>
        <td>{billing?.balance}</td>
        <td>{billing?.frozen_balance}</td>
        <td>{billing?.deposit}</td>
        <td>
          {moment.utc(billing?.created_at).local().format("DD.MM.YY, HH:mm:ss")}
        </td>
      </tr>
    ));
  };

  const renderUsersByAgent = () => {
    return usersByAgent?.map((user) => (
      <tr
        className={`${
          user?.is_banned === false && user?.type_active !== "active"
            ? "is_active"
            : user?.is_banned === true && user?.type_active !== "active"
            ? "is_banned"
            : user?.is_banned === true && user?.type_active === "active"
            ? "is_banned"
            : "transparent_trader"
        }`}
        key={user?.id}
      >
        <td>
          <NavLink
            onClick={() => {
              localStorage.setItem("user_id", user?.id);
            }}
            to={`/user/${user?.id}`}
            className="user__btn"
          >
            {user?.notice ? user?.notice : user?.id}
          </NavLink>
        </td>
        <td>{user?.role}</td>
        <td>{user?.agent_fee}</td>
        <td>{user?.is_banned === true ? "Заблокирован" : "Разблокирован"}</td>
        <td>
          {user?.type_active === "active"
            ? "Активен"
            : user?.type_active === "paused"
            ? "На паузе"
            : "Неактивен"}
        </td>
      </tr>
    ));
  };

  const openEditModal = () => {
    setEditModal(true);
    inputFee.setChange(
      user?.trader_data?.input_fee === null ? "" : user?.trader_data?.input_fee
    );
    outputFee.setChange(
      user?.trader_data?.output_fee === null
        ? ""
        : user?.trader_data?.output_fee
    );
    bonusInputFee.setChange(
      user?.trader_data?.bonus_input_fee === null
        ? ""
        : user?.trader_data?.bonus_input_fee
    );
    bonusOutputFee.setChange(
      user?.trader_data?.bonus_output_fee === null
        ? ""
        : user?.trader_data?.bonus_output_fee
    );
    dailyInputLimit.setChange(
      user?.trader_data?.daily_input_limit === null
        ? ""
        : user?.trader_data?.daily_input_limit
    );
    dailyOutputLimit.setChange(
      user?.trader_data?.daily_output_limit === null
        ? ""
        : user?.trader_data?.daily_output_limit
    );
    inputLimit.setChange(
      user?.trader_data?.input_limit === null
        ? ""
        : user?.trader_data?.input_limit
    );
    outputLimit.setChange(
      user?.trader_data?.output_limit === null
        ? ""
        : user?.trader_data?.output_limit
    );
    defaultMinAmountAds.setChange(
      user?.trader_data?.default_min_amount_ads === null
        ? ""
        : user?.trader_data?.default_min_amount_ads
    );
    const selectedCurrency = currencies.find(
      (currency) => currency.id === user?.trader_data?.currency_id
    );
    setСurrencySelect(
      selectedCurrency
        ? { value: selectedCurrency.id, label: selectedCurrency?.currency }
        : null
    );
    const rangSelect = rangData.find(
      (rang) => rang.value === user?.trader_data?.rang
    );
    setRangSelect(
      rangSelect ? { value: rangSelect.value, label: rangSelect.label } : null
    );
    const prioritySelect = priorityData.find(
      (rang) => rang.value === user?.trader_data?.priority
    );
    setPrioritySelect(
      prioritySelect
        ? { value: prioritySelect.value, label: prioritySelect.label }
        : null
    );
  };

  const closeEditModal = () => {
    setEditModal(false);
    inputFee.setChange("");
    outputFee.setChange("");
    bonusInputFee.setChange("");
    bonusOutputFee.setChange("");
    dailyInputLimit.setChange("");
    dailyOutputLimit.setChange("");
    inputLimit.setChange("");
    outputLimit.setChange("");
    defaultMinAmountAds.setChange("");
    setСurrencySelect(null);
    setRangSelect(null);
    setPrioritySelect(null);
  };

  const editSendData = () => {
    const body = {
      trader_data_id: parameters?.id,
      input_fee: inputFee.value,
      output_fee: outputFee.value,
      bonus_input_fee: bonusInputFee.value,
      bonus_output_fee: bonusOutputFee.value,
      daily_input_limit: dailyInputLimit.value,
      daily_output_limit: dailyOutputLimit.value,
      currency_id: currencySelect.value,
      rang: rangSelect.value,
      priority: prioritySelect.value,
      default_min_amount_ads: defaultMinAmountAds.value,
    };
    ApiTradersData.editTraderData(body, closeEditModal, setIsOnline);
  };

  const openInputLimit = () => {
    setAddLimitModal(true);
  };

  const openSubInputLimit = () => {
    setSubLimitModal(true);
  };

  const openOutputLimit = () => {
    setAddLimitOutputModal(true);
  };

  const openSubOutputLimit = () => {
    setSubLimitOutputModal(true);
  };

  const closeAddLimitModal = () => {
    setAddLimitModal(false);
    inputLimit.setChange("");
  };

  const closeSubLimitModal = () => {
    setSubLimitModal(false);
    subInputLimit.setChange("");
  };

  const closeAddLimitOutputModal = () => {
    setAddLimitOutputModal(false);
    outputLimit.setChange("");
  };

  const closeSubLimitOutputModal = () => {
    setSubLimitOutputModal(false);
    subOutputLimit.setChange("");
  };

  const addLimit = () => {
    const body = {
      trader_data_id: parameters?.id,
      input_limit: inputLimit.value,
    };
    ApiTradersData.addInputLimitToTrader(body, closeAddLimitModal, setIsOnline);
  };

  const subLimit = () => {
    const body = {
      trader_data_id: parameters?.id,
      input_limit: subInputLimit.value,
    };
    ApiTradersData.subInputLimitToTrader(body, closeSubLimitModal, setIsOnline);
  };

  const addOutputLimit = () => {
    const body = {
      trader_data_id: parameters?.id,
      output_limit: outputLimit.value,
    };
    ApiTradersData.addOutputLimitToTrader(
      body,
      closeAddLimitOutputModal,
      setIsOnline
    );
  };

  const subOutputLimitAction = () => {
    const body = {
      trader_data_id: parameters?.id,
      output_limit: subOutputLimit.value,
    };
    ApiTradersData.subOutputLimitToTrader(
      body,
      closeSubLimitOutputModal,
      setIsOnline
    );
  };

  const openDeleteModal = (payment) => {
    setDeleteModal(true);
    setInfoWallet(payment);
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
    setInfoWallet(null);
  };

  const openCreateModal = () => {
    setCreateModal(true);
  };

  const closeCreateModal = () => {
    setCreateModal(false);
    balance.setChange("");
    inputFeeMerchant.setChange("");
    outputFeeMerchant.setChange("");
    bonusInputFeeMerchant.setChange("");
    bonusOutputFeeMerchant.setChange("");
    setCurrencySelectMerchant(null);
  };

  const deleteMerchantWallet = () => {
    const body = {
      wallet_id: infoWallet.id,
    };
    merchantWalletApi.deleteMerchantWalletByWalletId(body, closeDeleteModal);
  };

  const createMerchantWallet = () => {
    const body = {
      balance: balance.value,
      input_fee: inputFeeMerchant.value,
      output_fee: outputFeeMerchant.value,
      bonus_input_fee: bonusInputFeeMerchant.value,
      bonus_output_fee: bonusOutputFeeMerchant.value,
      currency_id: currencySelectMerchant.value,
      user_id: parameters?.id,
    };
    merchantWalletApi.createMerchantWallets(body, closeCreateModal);
  };

  const renderPaymentMethodsData = () => {
    return merchantWallet?.map((item, index) => {
      return (
        <tr key={index}>
          <td>{item?.user_id}</td>
          <td>{item?.balance}</td>
          <td>{item?.input_fee}</td>
          <td>{item?.output_fee}</td>
          <td>{item?.bonus_input_fee}</td>
          <td>{item?.bonus_output_fee}</td>
          <td>{item?.currency?.code}</td>
          <td>{item?.currency?.currency}</td>
          <td>{item?.currency?.usdt_price}</td>
          <td>
            <NavLink
              onClick={() => {
                localStorage.setItem("wallet_id", item?.id);
              }}
              to={`/merchant-wallets/${item?.id}`}
              className="user__btn"
            >
              <img src="/assets/icons/eye-icon.svg" alt="" />
            </NavLink>
          </td>
          <td>
            <div
              onClick={() => {
                openDeleteModal(item);
              }}
              className="currencies__btn"
            >
              <img src="/assets/icons/trash-ad.svg" alt="" />
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <div className="userDetails container">
      {generateFaModal && (
        <Popup title={"Изменить 2fa ключ"} closePopup={closeGenerateFaKeyModal}>
          <div className="popup__form">
            <div>
              <Button
                text={"Сгенерировать новый fa ключ"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={generateKey}
              />
            </div>
            <div className="popup__input">
              <Input
                label={"Новый fa ключ"}
                placeholder=""
                type="text"
                id="newFaKey"
                {...newFaKey}
              />
            </div>
            <div className="popup__input">
              <Input
                label={"Повторите новый fa ключ"}
                type="text"
                placeholder=""
                id="repeatNewFaKey"
                {...repeatNewFaKey}
              />
            </div>
            <div className="popup__input">
              <Input
                label={"Введите свой fa ключ"}
                type="text"
                onlyNumber={true}
                maxLength={6}
                placeholder=""
                id="ownerFaKey"
                {...ownerFaKey}
              />
            </div>
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeGenerateFaKeyModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Сохранить"}
                topPadding={10}
                disabled={
                  ownerFaKey.value?.length === 6 &&
                  newFaKey.value === "" &&
                  repeatNewFaKey.value === ""
                    ? true
                    : false
                }
                bottomPadding={10}
                type="accept"
                onClick={sendData}
              />
            </div>
          </div>
        </Popup>
      )}
      {editDataModal && (
        <Popup
          title={"Изменить данные трейдера"}
          closePopup={closeEditDataModal}
        >
          <div className="popup__form">
            <div className="popup__input">
              <Input
                label={"Введите логин"}
                placeholder=""
                type="text"
                id="login"
                {...login}
              />
            </div>
            <div className="popup__input">
              <Input
                label={"Введите сервисный URL"}
                type="text"
                placeholder=""
                id="serviceUrl"
                {...serviceUrl}
              />
            </div>
            <div className="popup-select mt-10">
              <p>Тип аутентификации</p>
              <CustomSelect
                options={authType}
                selected={selectAuthType}
                placeholder={"Выберите тип аутентификации"}
                isClearable={false}
                handleSelect={(e) => {
                  setSelectAuthType(e ?? null);
                }}
              />
            </div>
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeEditDataModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Сохранить"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={sendEditData}
              />
            </div>
          </div>
        </Popup>
      )}
      {editPasswordModal && (
        <Popup title={"Сменить пароль"} closePopup={closeEditPasswordModal}>
          <div className="popup__form">
            <div className="popup__input">
              <Input
                label={"Введите новый пароль"}
                placeholder=""
                type="text"
                id="newPassword"
                {...newPassword}
              />
            </div>
            <div className="popup__input">
              <Input
                label={"Повторите новый пароль"}
                type="text"
                placeholder=""
                id="repeatNewPassword"
                {...repeatNewPassword}
              />
            </div>
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeEditPasswordModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Сохранить"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={sendEditPassword}
              />
            </div>
          </div>
        </Popup>
      )}
      {createWalletModal && (
        <Popup title={"Cоздать кошелек"} closePopup={closeCreateWalletModal}>
          <div className="popup__form">
            <div className="popup__inputs">
              <div className="popup-select mt-10">
                <p>Выберите валюту</p>
                <CustomSelect
                  options={currencies.map((currency) => {
                    return {
                      value: currency.id,
                      label: currency.currency,
                    };
                  })}
                  selected={currencySelect}
                  id="currency"
                  handleSelect={(e) => {
                    setСurrencySelect(e ?? null);
                  }}
                  isClearable={true}
                />
              </div>
            </div>
            <div className="popup__inputs">
              <div className="popup-select mt-10">
                <p>Выберите мерчанта</p>
                <CustomSelect
                  options={usersMerchant?.map((user) => {
                    return {
                      value: user.id,
                      label: user.notice !== null ? user.notice : user.login,
                    };
                  })}
                  selected={selectMerchant}
                  id="merchantUsers"
                  handleSelect={(e) => {
                    setSelectMerchant(e ?? null);
                  }}
                  isClearable={true}
                />
              </div>
            </div>
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeCreateWalletModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Создать"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={createWallet}
              />
            </div>
          </div>
        </Popup>
      )}
      {deleteWalletModal && (
        <Popup title={"Удалить кошелек"} closePopup={closeDeleteWalletModal}>
          <p className="delete-text">Вы уверены что хотите удалить кошелек?</p>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeDeleteWalletModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Удалить"}
                topPadding={10}
                bottomPadding={10}
                type="remove"
                onClick={deleteSendData}
              />
            </div>
          </div>
        </Popup>
      )}
      {addModal && (
        <Popup title={"Добавить депозит"} closePopup={closeAddModal}>
          <div className="add-deposit__inner">
            <Input
              placeholder="Введите число"
              label="Добавить депозит"
              type="number"
              id="add"
              value={addNumber}
              handleChange={(e) => setAddNumber(e.target.value)}
            />
            <Button
              text={"Добавить"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={addDeposit}
            />
          </div>
        </Popup>
      )}
      {addBalanceModal && (
        <Popup title={"Добавить баланс"} closePopup={closeAddBalanceModal}>
          <div className="add-deposit__inner">
            <Input
              placeholder="Введите число"
              type="number"
              label="Добавить баланс"
              id="add"
              value={addBalanceNumber}
              handleChange={(e) => setAddBalanceNumber(e.target.value)}
            />
            <Button
              text={"Добавить"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={addBalance}
            />
          </div>
        </Popup>
      )}
      {addFrozenModal && (
        <Popup
          title={"Добавить замороженный баланс"}
          closePopup={closeAddFrozenModal}
        >
          <div className="add-deposit__inner">
            <Input
              placeholder="Введите число"
              type="number"
              label="Добавить замороженный баланс"
              id="add"
              value={addFrozenNumber}
              handleChange={(e) => setAddFrozenNumber(e.target.value)}
            />
            <Button
              text={"Добавить"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={addFrozenBalance}
            />
          </div>
        </Popup>
      )}
      {subModal && (
        <Popup title={"Вычесть депозит"} closePopup={closeSubModal}>
          <div className="add-deposit__inner">
            <Input
              placeholder="Введите число"
              type="number"
              label="Вычесть депозит"
              id="sub"
              value={subNumber}
              handleChange={(e) => setSubNumber(e.target.value)}
            />
            <Button
              text={"Вычесть"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={subDeposit}
            />
          </div>
        </Popup>
      )}
      {subBalanceModal && (
        <Popup title={"Вычесть баланс"} closePopup={closeSubBalanceModal}>
          <div className="add-deposit__inner">
            <Input
              placeholder="Введите число"
              type="number"
              label="Вычесть баланс"
              id="sub"
              value={subBalanceNumber}
              handleChange={(e) => setSubBalanceNumber(e.target.value)}
            />
            <Button
              text={"Вычесть"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={subBalance}
            />
          </div>
        </Popup>
      )}
      {subFrozenModal && (
        <Popup
          title={"Вычесть замороженный баланс"}
          closePopup={closeSubFrozenModal}
        >
          <div className="add-deposit__inner">
            <Input
              placeholder="Введите число"
              type="number"
              label="Вычесть замороженный баланс"
              id="sub"
              value={subFrozenNumber}
              handleChange={(e) => setSubFrozenNumber(e.target.value)}
            />
            <Button
              text={"Вычесть"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={subFrozen}
            />
          </div>
        </Popup>
      )}
      {createCryptoWalletModal && (
        <Popup
          title={"Сменить крипто кошелек"}
          closePopup={closeCreateCryptoWalletModal}
        >
          <div className="ads__popup-btns">
            <div className="ads__popup-btn">
              <Button
                text="Отмена"
                type="light"
                onClick={closeCreateCryptoWalletModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="ads__popup-btn">
              <Button
                text="Сменить кошелек"
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={createCryptoWallet}
              />
            </div>
          </div>
        </Popup>
      )}
      {editModal && (
        <Popup title={"Изменить данные трейдера"} closePopup={closeEditModal}>
          <div className="popup__form">
            <div className="popup__inputs">
              <div className="popup__input">
                <Input
                  label={"Дневной лимит ввода"}
                  placeholder="Введите лимит ввода"
                  type="text"
                  onlyNumber={true}
                  id="dailyInputLimit"
                  {...dailyInputLimit}
                />
              </div>
              <div className="popup__input">
                <Input
                  label={"Дневной лимит вывода"}
                  placeholder="Введите лимит вывода"
                  type="text"
                  onlyNumber={true}
                  id="dailyOutputLimit"
                  {...dailyOutputLimit}
                />
              </div>
            </div>
            <div className="popup__inputs mt-10">
              <div className="popup-select">
                <p>Выберите валюту</p>
                <CustomSelect
                  options={currencies.map((currency) => {
                    return {
                      value: currency.id,
                      label: currency.currency,
                    };
                  })}
                  selected={currencySelect}
                  id="currency"
                  handleSelect={(e) => {
                    setСurrencySelect(e ?? null);
                  }}
                  isClearable={true}
                />
              </div>
            </div>
            <div className="popup__inputs mt-10">
              <div className="popup-select">
                <p>Выберите рейтинг</p>
                <CustomSelect
                  options={rangData}
                  selected={rangSelect}
                  id="rang"
                  handleSelect={(e) => {
                    setRangSelect(e ?? null);
                  }}
                  isClearable={true}
                />
              </div>
            </div>
            <div className="popup__inputs mt-10">
              <div className="popup-select">
                <p>Выберите приоритет</p>
                <CustomSelect
                  options={priorityData}
                  selected={prioritySelect}
                  id="priority"
                  handleSelect={(e) => {
                    setPrioritySelect(e ?? null);
                  }}
                  isClearable={true}
                />
              </div>
            </div>
            <div className="popup__input">
              <Input
                label={"Минимальный лимит"}
                placeholder="Введите мин лимиту"
                type="text"
                id="defaultMinAmountAds"
                onlyNumber={true}
                {...defaultMinAmountAds}
              />
            </div>
            <div className="popup__input">
              <Input
                label={"Input fee"}
                placeholder="Введите Input fee"
                type="text"
                onlyNumber={true}
                id="inputFee"
                {...inputFee}
              />
            </div>
            <div className="popup__input">
              <Input
                label={"Output fee"}
                placeholder="Введите outputFee"
                type="text"
                onlyNumber={true}
                id="outputFee"
                {...outputFee}
              />
            </div>
            <div className="popup__inputs">
              <div className="popup__input">
                <Input
                  label={"Bonus input fee"}
                  placeholder="Введите . . ."
                  type="text"
                  onlyNumber={true}
                  id="bonusInputFee"
                  {...bonusInputFee}
                />
              </div>
              <div className="popup__input">
                <Input
                  label={"Bonus output fee"}
                  placeholder="Введите . . ."
                  type="text"
                  onlyNumber={true}
                  id="bonusOutputFee"
                  {...bonusOutputFee}
                />
              </div>
            </div>
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeEditModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Сохранить"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={editSendData}
              />
            </div>
          </div>
        </Popup>
      )}
      {addLimitModal && (
        <Popup title={"Добавить лимит на ввод"} closePopup={closeAddLimitModal}>
          <div className="add-deposit__inner mt-10">
            <Input
              label="Лимит на ввод"
              placeholder="Введите число"
              type="number"
              id="add"
              value={inputLimit.value}
              handleChange={(e) => inputLimit.setChange(e.target.value)}
            />
            <Button
              text={"Добавить"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={addLimit}
            />
          </div>
        </Popup>
      )}
      {addLimitOutputModal && (
        <Popup
          title={"Добавить лимит на вывод"}
          closePopup={closeAddLimitOutputModal}
        >
          <div className="add-deposit__inner mt-10">
            <Input
              label="Лимит на вывод"
              placeholder="Введите число"
              type="number"
              id="add"
              value={outputLimit.value}
              handleChange={(e) => outputLimit.setChange(e.target.value)}
            />
            <Button
              text={"Добавить"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={addOutputLimit}
            />
          </div>
        </Popup>
      )}
      {subLimitOutputModal && (
        <Popup
          title={"Вычесть лимит на вывод"}
          closePopup={closeSubLimitOutputModal}
        >
          <div className="add-deposit__inner mt-10">
            <Input
              label="Лимит на вывод"
              placeholder="Введите число"
              type="number"
              id="add"
              value={subOutputLimit.value}
              handleChange={(e) => subOutputLimit.setChange(e.target.value)}
            />
            <Button
              text={"Вычесть"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={subOutputLimitAction}
            />
          </div>
        </Popup>
      )}
      {subLimitModal && (
        <Popup title={"Вычесть лимит на ввод"} closePopup={closeSubLimitModal}>
          <div className="add-deposit__inner mt-10">
            <Input
              label="Лимит на ввод"
              placeholder="Введите число"
              type="number"
              id="add"
              value={subInputLimit.value}
              handleChange={(e) => subInputLimit.setChange(e.target.value)}
            />
            <Button
              text={"Вычесть"}
              topPadding={10}
              bottomPadding={10}
              type="accept"
              onClick={subLimit}
            />
          </div>
        </Popup>
      )}
      {deleteModal && (
        <Popup title={"Удалить кошелек"} closePopup={closeDeleteModal}>
          <p className="delete-text">Вы уверены что хотите способ оплаты?</p>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeDeleteModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Удалить"}
                type="remove"
                onClick={deleteMerchantWallet}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
          </div>
        </Popup>
      )}
      {createModal && (
        <Popup title={"Cоздать кошелек"} closePopup={closeCreateModal}>
          <div className="popup__form">
            <div className="popup__input">
              <Input
                label={"Баланс"}
                placeholder="Введите баланс"
                type="text"
                id="balance"
                {...balance}
              />
            </div>
            <div className="popup__inputs">
              <div className="popup__input">
                <Input
                  label={"Input fee"}
                  placeholder="Введите . . ."
                  type="text"
                  id="inputFee"
                  {...inputFeeMerchant}
                />
              </div>
              <div className="popup__input">
                <Input
                  label={"OutputFee"}
                  placeholder="Введите . . ."
                  type="text"
                  id="outputFee"
                  {...outputFeeMerchant}
                />
              </div>
            </div>
            <div className="popup__inputs">
              <div className="popup__input">
                <Input
                  label={"Bonus input fee"}
                  placeholder="Введите . . ."
                  type="text"
                  id="bonusInputFee"
                  {...bonusInputFeeMerchant}
                />
              </div>
              <div className="popup__input">
                <Input
                  label={"Bonus output fee"}
                  placeholder="Введите . . ."
                  type="text"
                  id="bonusOutputFee"
                  {...bonusOutputFeeMerchant}
                />
              </div>
            </div>
            <div className="popup__inputs">
              <div className="popup-select">
                <p>Выберите валюту</p>
                <CustomSelect
                  options={currencies.map((currency) => {
                    return {
                      value: currency.id,
                      label: currency.currency,
                    };
                  })}
                  selected={currencySelectMerchant}
                  id="currencyMerchant"
                  handleSelect={(e) => {
                    setCurrencySelectMerchant(e ?? null);
                  }}
                  isClearable={true}
                />
              </div>
            </div>
          </div>
          <div className="popup__btns">
            <div className="popup__btn">
              <Button
                text={"Отмена"}
                type="light"
                onClick={closeCreateModal}
                topPadding={10}
                bottomPadding={10}
              />
            </div>
            <div className="popup__btn">
              <Button
                text={"Сохранить"}
                topPadding={10}
                bottomPadding={10}
                type="accept"
                onClick={createMerchantWallet}
              />
            </div>
          </div>
        </Popup>
      )}
      <Header title={"Детали пользователя"} />
      <div className="userDetails__inner">
        <div
          onClick={() => {
            navigate(-1);
          }}
          className="userDetails__backBtn"
        >
          <img src="/assets/icons/back-arrow.svg" alt="" />
        </div>
        <div className="userDetails__wrapper">
          <div className="userDetails__left">
            <div className="userDetails__left-btns">
              <div className="editFa__btn">
                <Button
                  text={"Изменить fa ключ"}
                  topPadding={10}
                  bottomPadding={10}
                  type="accept"
                  onClick={openGenerateFaKeyModal}
                />
              </div>
              {((user?.role === "trader" && !user?.is_service) && profileData?.role === 'superadmin') && (
                  <div className="editFa__btn">
                    <Button
                      text={"Изменить данные трейдера"}
                      topPadding={10}
                      bottomPadding={10}
                      type="accept"
                      onClick={openEditModal}
                    />
                  </div>
                )}
            </div>
            {user?.role === "trader" && !user?.is_service && (
              <div className="traders-data__top-right">
                <div className="traders-data__btn">
                  <Button
                    text={"Добавить лимит на ввод"}
                    topPadding={10}
                    bottomPadding={10}
                    leftPadding={4}
                    rightPadding={4}
                    onClick={openInputLimit}
                  />
                </div>
                <div className="traders-data__btn">
                  <Button
                    text={"Вычесть лимит на ввод"}
                    topPadding={10}
                    bottomPadding={10}
                    leftPadding={4}
                    rightPadding={4}
                    onClick={openSubInputLimit}
                  />
                </div>
                <div className="traders-data__btn">
                  <Button
                    text={"Добавить лимит на вывод"}
                    topPadding={10}
                    bottomPadding={10}
                    leftPadding={4}
                    rightPadding={4}
                    onClick={openOutputLimit}
                  />
                </div>
                <div className="traders-data__btn">
                  <Button
                    text={"Вычесть лимит на вывод"}
                    topPadding={10}
                    bottomPadding={10}
                    leftPadding={4}
                    rightPadding={4}
                    onClick={openSubOutputLimit}
                  />
                </div>
              </div>
            )}
            {user?.role === "trader" ||
            user?.role === "agent" ||
            user?.role === "arbitr" ? (
              <>
                <table>
                  <tr>
                    <th>ID пользователя</th>
                    <td>{user?.id}</td>
                  </tr>
                  <tr>
                    <th>Роль</th>
                    <td>
                      {user?.role === "trader" && user?.is_service
                        ? "service_trader"
                        : user?.role === "trader" && !user?.is_service
                        ? "trader"
                        : user?.role}
                    </td>
                  </tr>
                  <tr>
                    <th>Логин</th>
                    <td>{user?.login}</td>
                  </tr>
                  <tr>
                    <th>Имя</th>
                    <td>{user?.notice}</td>
                  </tr>
                  <tr>
                    <th>Телеграм ID</th>
                    <td>{user?.telegram_id}</td>
                  </tr>
                  {user?.role !== "agent" &&
                    !user?.is_service &&
                    user?.role !== "arbitr" && (
                      <tr>
                        <th>Валюта</th>
                        <td>
                          {
                            currencies.find(
                              (currency) =>
                                currency.id === user?.trader_data?.currency_id
                            )?.currency
                          }
                        </td>
                      </tr>
                    )}
                  {user?.role !== "agent" &&
                    !user?.is_service &&
                    user?.role !== "arbitr" && (
                      <tr>
                        <th>Рейтинг</th>
                        <td>{user?.trader_data?.rang}</td>
                      </tr>
                    )}
                  {user?.role !== "agent" &&
                    !user?.is_service &&
                    user?.role !== "arbitr" && (
                      <tr>
                        <th>Приоритет</th>
                        <td>{user?.trader_data?.priority}</td>
                      </tr>
                    )}
                  {user?.role !== "agent" && user?.role !== "arbitr" && (
                    <tr>
                      <th>Время сессии</th>
                      <td>{user?.session_timeout}</td>
                    </tr>
                  )}
                  {!user?.is_service && user?.role !== "arbitr" && (
                    <tr>
                      <th>Крипто адрес</th>
                      <td>{user?.crypto_wallet?.crypto_address}</td>
                    </tr>
                  )}
                  {!user?.is_service && user?.role !== "arbitr" && (
                    <tr>
                      <th>Заработанный баланс</th>
                      <td>{user?.crypto_wallet?.balance}</td>
                    </tr>
                  )}
                  {!user?.is_service && user?.role !== "arbitr" && (
                    <tr>
                      <th>Замороженный баланс</th>
                      <td>{user?.crypto_wallet?.frozen_balance}</td>
                    </tr>
                  )}
                  {!user?.is_service && user?.role !== "arbitr" && (
                    <tr>
                      <th>Рабочий депозит</th>
                      <td>{user?.crypto_wallet?.deposit}</td>
                    </tr>
                  )}
                </table>
                {user?.role !== "agent" &&
                  !user?.is_service &&
                  profileData?.role === "superadmin" && (
                    <>
                      <p className="title_table">Данные трейдера</p>
                      <table>
                        <tr>
                          <th>Комиссия на ввод</th>
                          <td>{user?.trader_data?.input_fee}</td>
                        </tr>
                        <tr>
                          <th>Комиссия на вывод</th>
                          <td>{user?.trader_data?.output_fee}</td>
                        </tr>
                        <tr>
                          <th>Комиссия на ввод бонус</th>
                          <td>{user?.trader_data?.bonus_input_fee}</td>
                        </tr>
                        <tr>
                          <th>Комиссия на вывод бонус</th>
                          <td>{user?.trader_data?.bonus_output_fee}</td>
                        </tr>
                        <tr>
                          <th>Минимальный лимит</th>
                          <td>{user?.trader_data?.default_min_amount_ads}</td>
                        </tr>
                        <tr>
                          <th>Ставка агента</th>
                          <td>{user?.agent_fee}</td>
                        </tr>
                        <tr>
                          <th>Дневной лимит на ввод</th>
                          <td>{user?.trader_data?.daily_input_limit}</td>
                        </tr>
                        <tr>
                          <th>Дневной лимит на вывод</th>
                          <td>{user?.trader_data?.daily_output_limit}</td>
                        </tr>
                        <tr>
                          <th>Остаток лимита на ввод</th>
                          <td>{user?.trader_data?.input_limit}</td>
                        </tr>
                        <tr>
                          <th>Остаток лимита на вывод</th>
                          <td>{user?.trader_data?.output_limit}</td>
                        </tr>
                        <tr>
                          <th>Кол-во заявок на ввод</th>
                          <td>{user?.trader_data?.total_buy_orders}</td>
                        </tr>
                        <tr>
                          <th>Кол-во заявок на вывод</th>
                          <td>{user?.trader_data?.total_sell_orders}</td>
                        </tr>
                        <tr>
                          <th>Кол-во успешных заявок на ввод</th>
                          <td>{user?.trader_data?.total_success_buy_orders}</td>
                        </tr>
                        <tr>
                          <th>Кол-во успешных заявок на вывод</th>
                          <td>
                            {user?.trader_data?.total_success_sell_orders}
                          </td>
                        </tr>
                        <tr>
                          <th>Кол-во отмененных заявок на ввод</th>
                          <td>{user?.trader_data?.total_cancel_buy_orders}</td>
                        </tr>
                        <tr>
                          <th>Кол-во отмененных заявок на вывод</th>
                          <td>{user?.trader_data?.total_cancel_sell_orders}</td>
                        </tr>
                        <tr>
                          <th>Кол-во апелляций на ввод</th>
                          <td>{user?.trader_data?.total_appeal_buy_orders}</td>
                        </tr>
                        <tr>
                          <th>Кол-во апелляций на вывод</th>
                          <td>{user?.trader_data?.total_appeal_sell_orders}</td>
                        </tr>
                      </table>
                    </>
                  )}
                {user?.role === "agent" &&
                  profileData?.role === "superadmin" && (
                    <>
                      <p className="title_table">Подключенные пользователи</p>
                      <table>
                        <thead>
                          <tr>
                            <th>Название</th>
                            <th>Роль</th>
                            <th>Комиссия агента</th>
                            <th>Блокировка</th>
                            <th>Активность</th>
                          </tr>
                        </thead>
                        {usersByAgent?.length > 0 && (
                          <tbody>{renderUsersByAgent()}</tbody>
                        )}
                      </table>
                      {usersByAgent?.length > limitCountAgent && (
                        <div className="loadMore">
                          <p
                            onClick={() => {
                              loadMoreAgent();
                            }}
                          >
                            Загрузить еще...
                          </p>
                        </div>
                      )}
                    </>
                  )}
              </>
            ) : user?.role === "merchant_server" ? (
              <table>
                <tr>
                  <th>ID пользователя</th>
                  <td>{user?.id}</td>
                </tr>
                <tr>
                  <th>Роль</th>
                  <td>{user?.role}</td>
                </tr>
                <tr>
                  <th>Логин</th>
                  <td>{user?.login}</td>
                </tr>
                <tr>
                  <th>Имя</th>
                  <td>{user?.notice}</td>
                </tr>
                <tr>
                  <th>Время сессии</th>
                  <td>{user?.session_timeout}</td>
                </tr>
                <tr>
                  <th>Список IP адресов</th>
                  <td>{user?.ip_list?.join(", ")}</td>
                </tr>
                <tr>
                  <th>Ставка агента</th>
                  <td>{user?.agent_fee}</td>
                </tr>
              </table>
            ) : (
              <></>
            )}
          </div>
          {user?.is_service && (
            <div className="userDetails__right">
              <div className="userDetails__right-btns">
                <div className="editFa__btn">
                  <Button
                    text={"Изменить данные"}
                    topPadding={10}
                    bottomPadding={10}
                    type="accept"
                    onClick={() => {
                      openEditDataModal();
                    }}
                  />
                </div>
                <div className="editFa__btn">
                  <Button
                    text={"Сменить пароль"}
                    topPadding={10}
                    bottomPadding={10}
                    type="accept"
                    onClick={() => {
                      openEditPasswordModal();
                    }}
                  />
                </div>
                <div className="editFa__btn">
                  <Button
                    text={"Создать кошелек"}
                    topPadding={10}
                    bottomPadding={10}
                    type="accept"
                    onClick={() => {
                      openCreateWalletModal();
                    }}
                  />
                </div>
              </div>

              <table>
                <tr>
                  <th>Логин</th>
                  <td>
                    {serviceTraderCredentials?.login
                      ? serviceTraderCredentials?.login
                      : "--- --- ---"}
                  </td>
                </tr>
                <tr>
                  <th>Сервисный URL</th>
                  <td>
                    {serviceTraderCredentials?.service_url
                      ? serviceTraderCredentials?.service_url
                      : "--- --- ---"}
                  </td>
                </tr>
                <tr>
                  <th>Тип аутентификации</th>
                  <td>
                    {serviceTraderCredentials?.type_auth
                      ? serviceTraderCredentials?.type_auth
                      : "--- --- ---"}
                  </td>
                </tr>
                <tr>
                  <th>Дата создания</th>
                  <td>
                    {moment
                      .utc(serviceTraderCredentials?.created_at)
                      .local()
                      .format("DD.MM.YY, HH:mm:ss")}
                  </td>
                </tr>
                <tr>
                  <th>Дата изменения</th>
                  <td>
                    {serviceTraderCredentials?.updated_at
                      ? moment
                          .utc(serviceTraderCredentials?.updated_at)
                          .local()
                          .format("DD.MM.YY, HH:mm:ss")
                      : "--- --- ---"}
                  </td>
                </tr>
              </table>
            </div>
          )}
          {user?.role !== "merchant_server" &&
            user?.is_service === false &&
            profileData?.role === "superadmin" && (
              <div className="userDetails__right userDetails__right_mt">
                <h2 className="userDetails__title">Крипто кошелек</h2>
                <div className="userDetails__right-btns">
                  <div className="crypto-wallets__deposit-btn">
                    <Button
                      className="crypto-wallets__add-btn"
                      text={"Добавить депозит"}
                      topPadding={10}
                      bottomPadding={10}
                      leftPadding={5}
                      rightPadding={5}
                      type="accept"
                      onClick={openAddModal}
                    />
                  </div>
                  <div className="crypto-wallets__deposit-btn">
                    <Button
                      className="crypto-wallets__add-btn"
                      text={"Добавить баланс"}
                      topPadding={10}
                      bottomPadding={10}
                      leftPadding={5}
                      rightPadding={5}
                      type="accept"
                      onClick={openAddBalanceModal}
                    />
                  </div>
                  <div className="crypto-wallets__deposit-btn">
                    <Button
                      className="crypto-wallets__add-btn"
                      text={"Добавить замор. баланс"}
                      topPadding={10}
                      bottomPadding={10}
                      leftPadding={5}
                      rightPadding={5}
                      type="accept"
                      onClick={openAddFrozenModal}
                    />
                  </div>
                </div>
                <div className="userDetails__right-btns mb-10">
                  <div className="crypto-wallets__deposit-btn">
                    <Button
                      text={"Вычесть депозит"}
                      topPadding={10}
                      bottomPadding={10}
                      leftPadding={5}
                      rightPadding={5}
                      type="primary"
                      onClick={openSubModal}
                    />
                  </div>
                  <div className="crypto-wallets__deposit-btn">
                    <Button
                      text={"Вычесть баланс"}
                      topPadding={10}
                      bottomPadding={10}
                      leftPadding={5}
                      rightPadding={5}
                      type="primary"
                      onClick={openSubBalanceModal}
                    />
                  </div>
                  <div className="crypto-wallets__deposit-btn">
                    <Button
                      text={"Вычесть замор. баланс"}
                      topPadding={10}
                      bottomPadding={10}
                      leftPadding={5}
                      rightPadding={5}
                      type="primary"
                      onClick={openSubFrozenModal}
                    />
                  </div>
                  <div className="crypto-wallets__deposit-btn">
                    <Button
                      text={"Сменить крипто кошелек"}
                      topPadding={10}
                      bottomPadding={10}
                      leftPadding={5}
                      rightPadding={5}
                      type="primary"
                      onClick={openCreateCryptoWalletModal}
                    />
                  </div>
                </div>
                <div className="statistics__toggle mt-10">
                  <p>Заморозить кошелек</p>
                  <div
                    onClick={() => {
                      apiCryptoWallets.activeWallet(
                        {
                          wallet_id: cryptoWallet?.id,
                        },
                        setIsOnline
                      );
                      setIsOnline(!isOnline);
                    }}
                    className={`navbar__online-toggle ${
                      isOnline ? "navbar__online-toggle-active" : ""
                    }`}
                  >
                    <div
                      className={`navbar__online-toggle-circle ${
                        isOnline ? "navbar__online-toggle-circle-active" : ""
                      }`}
                    ></div>
                  </div>
                </div>

                <div className="statistics__toggle">
                  <p>Страховой депозит</p>
                  <div
                    onClick={() => {
                      apiCryptoWallets.activeDeposit(
                        {
                          wallet_id: cryptoWallet?.id,
                        },
                        setIsOnline
                      );
                      setIsDeposit(!isDeposit);
                    }}
                    className={`navbar__online-toggle ${
                      cryptoWallet?.is_deposit
                        ? "navbar__online-toggle-active"
                        : ""
                    }`}
                  >
                    <div
                      className={`navbar__online-toggle-circle ${
                        cryptoWallet?.is_deposit
                          ? "navbar__online-toggle-circle-active"
                          : ""
                      }`}
                    ></div>
                  </div>
                </div>

                <table>
                  <tr>
                    <th>ID кошелька</th>
                    <td>{cryptoWallet?.id}</td>
                  </tr>
                  <tr>
                    <th>Тип валюты</th>
                    <td>{cryptoWallet?.currency_type}</td>
                  </tr>
                  <tr>
                    <th>Крипто адрес</th>
                    <td>{cryptoWallet?.crypto_address}</td>
                  </tr>
                  <tr>
                    <th>QR Code</th>
                    <td className="qr_code">
                      <img src={cryptoWallet?.qr_code_path} alt="" />
                    </td>
                  </tr>
                  <tr>
                    <th>Баланс</th>
                    <td>{cryptoWallet?.balance}</td>
                  </tr>
                  <tr>
                    <th>Замороженный баланс</th>
                    <td>{cryptoWallet?.frozen_balance}</td>
                  </tr>
                  <tr>
                    <th>Депозит</th>
                    <td>{cryptoWallet?.deposit}</td>
                  </tr>
                  <tr>
                    <th>Страховой депозит</th>
                    <td>{cryptoWallet?.is_deposit ? "Есть" : "Не внесен"}</td>
                  </tr>
                  <tr>
                    <th>Активность кошелька</th>
                    <td>
                      {cryptoWallet?.is_wallet ? "Активен" : "Заблокирован"}
                    </td>
                  </tr>
                </table>
                <h2 className="userDetails__title">Статистика кошелька</h2>
                <table>
                  <tr>
                    <th>Common Balance</th>
                    <td>{cryptoWalletStatistics?.common_balance}</td>
                  </tr>
                  <tr>
                    <th>Common Frozen Balance</th>
                    <td>{cryptoWalletStatistics?.common_frozen_balance}</td>
                  </tr>
                  <tr>
                    <th>Common Deposit</th>
                    <td>{cryptoWalletStatistics?.common_deposit}</td>
                  </tr>
                </table>
              </div>
            )}
          {user?.role === "trader" &&
            !user?.is_service &&
            profileData?.role === "arbitr" && (
              <div className="w-50">
                <p className="title_table">Данные трейдера</p>
                <table>
                  {user?.role === "superadmin" && (
                    <tr>
                      <th>Комиссия на ввод</th>
                      <td>{user?.trader_data?.input_fee}</td>
                    </tr>
                  )}
                  {user?.role === "superadmin" && (
                    <tr>
                      <th>Комиссия на вывод</th>
                      <td>{user?.trader_data?.output_fee}</td>
                    </tr>
                  )}
                  {user?.role === "superadmin" && (
                    <tr>
                      <th>Комиссия на ввод бонус</th>
                      <td>{user?.trader_data?.bonus_input_fee}</td>
                    </tr>
                  )}
                  {user?.role === "superadmin" && (
                    <tr>
                      <th>Комиссия на вывод бонус</th>
                      <td>{user?.trader_data?.bonus_output_fee}</td>
                    </tr>
                  )}
                  <tr>
                    <th>Минимальный лимит</th>
                    <td>{user?.trader_data?.default_min_amount_ads}</td>
                  </tr>
                  <tr>
                    <th>Ставка агента</th>
                    <td>{user?.agent_fee}</td>
                  </tr>
                  <tr>
                    <th>Дневной лимит на ввод</th>
                    <td>{user?.trader_data?.daily_input_limit}</td>
                  </tr>
                  <tr>
                    <th>Дневной лимит на вывод</th>
                    <td>{user?.trader_data?.daily_output_limit}</td>
                  </tr>
                  <tr>
                    <th>Остаток лимита на ввод</th>
                    <td>{user?.trader_data?.input_limit}</td>
                  </tr>
                  <tr>
                    <th>Остаток лимита на вывод</th>
                    <td>{user?.trader_data?.output_limit}</td>
                  </tr>
                  <tr>
                    <th>Кол-во заявок на ввод</th>
                    <td>{user?.trader_data?.total_buy_orders}</td>
                  </tr>
                  <tr>
                    <th>Кол-во заявок на вывод</th>
                    <td>{user?.trader_data?.total_sell_orders}</td>
                  </tr>
                  <tr>
                    <th>Кол-во успешных заявок на ввод</th>
                    <td>{user?.trader_data?.total_success_buy_orders}</td>
                  </tr>
                  <tr>
                    <th>Кол-во успешных заявок на вывод</th>
                    <td>{user?.trader_data?.total_success_sell_orders}</td>
                  </tr>
                  <tr>
                    <th>Кол-во отмененных заявок на ввод</th>
                    <td>{user?.trader_data?.total_cancel_buy_orders}</td>
                  </tr>
                  <tr>
                    <th>Кол-во отмененных заявок на вывод</th>
                    <td>{user?.trader_data?.total_cancel_sell_orders}</td>
                  </tr>
                  <tr>
                    <th>Кол-во апелляций на ввод</th>
                    <td>{user?.trader_data?.total_appeal_buy_orders}</td>
                  </tr>
                  <tr>
                    <th>Кол-во апелляций на вывод</th>
                    <td>{user?.trader_data?.total_appeal_sell_orders}</td>
                  </tr>
                </table>
              </div>
            )}
        </div>
        {user?.is_service && serviceTraderWallets?.length > 0 && (
          <>
            <h2 className="userDetails__title">Кошельки сервисного трейдера</h2>
            <div className="userDetails__table">
              <table>
                <thead>
                  <tr>
                    <th>Мерчант</th>
                    <th>Валюта</th>
                    <th>Баланс</th>
                    <th>Депозит</th>
                    <th>Замороженный баланс</th>
                    <th>Дата создания</th>
                    <th>Дата изменения</th>
                  </tr>
                </thead>
                <tbody>{renderWallets()}</tbody>
              </table>
            </div>
          </>
        )}
        {user?.role === "merchant_server" &&
          profileData?.role === "superadmin" && (
            <>
              <div className="userDetails__title_top">
                <h2 className="userDetails__title">Кошельки мерчанта</h2>
                <div className="merchantWallet__btn">
                  <Button
                    text={"Создать кошелек"}
                    topPadding={10}
                    bottomPadding={10}
                    type="accept"
                    onClick={openCreateModal}
                  />
                </div>
              </div>
              <div className="userDetails__table">
                <table>
                  <thead>
                    <tr>
                      <th>Юзер ID</th>
                      <th>Баланс</th>
                      <th>Input fee</th>
                      <th>Output fee</th>
                      <th>Bonus input fee</th>
                      <th>Bonus output fee</th>
                      <th>Код</th>
                      <th>Валюта</th>
                      <th>USDT Price</th>
                    </tr>
                  </thead>
                  <tbody>{renderPaymentMethodsData()}</tbody>
                </table>
              </div>
            </>
          )}
      </div>
      {user?.role !== "merchant_server" &&
        user?.is_service === false &&
        profileData?.role === "superadmin" && (
          <>
            <h2 className="crypto-wallets__title">
              Биллинг по кошельку
              <div className="crypto-wallets__select">
                <CustomSelect
                  options={typeData}
                  placeholder={"Выберите тип транзакции"}
                  selected={selectedTransaction}
                  handleSelect={(e) => {
                    dispatch(setCryptoWalletBillingEmpty([]));
                    setSelectedTransaction(e ?? null);
                  }}
                  isClearable={true}
                />
              </div>
            </h2>
            <div className="table_width">
              <table>
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Currency Type</th>
                    <th>Operation Type</th>
                    <th>Transaction Type</th>
                    <th>Balance</th>
                    <th>Frozen Balance</th>
                    <th>Deposit</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                {cryptoWalletBilling?.length > 0 && (
                  <tbody>{renderBilling()}</tbody>
                )}
              </table>
              <div className="loadMore">
                <p
                  onClick={() => {
                    loadMore();
                  }}
                >
                  Загрузить еще...
                </p>
              </div>
            </div>
          </>
        )}
    </div>
  );
}
