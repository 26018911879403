import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import axios from "../axios";
import PhoneInput from "react-phone-number-input";
import DatePicker from "rsuite/DatePicker";
import "rsuite/DateRangePicker/styles/index.css";
import "react-phone-number-input/style.css";
import {
  Select,
  Input,
  Flex,
  Button,
  message,
  Modal,
  Table,
  Upload,
  InputNumber,
  Tooltip,
  Popconfirm,
} from "antd";
import {
  SearchOutlined,
  FundViewOutlined,
  FileProtectOutlined,
  ArrowDownOutlined,
  InboxOutlined,
  DeleteOutlined,
  SnippetsOutlined,
  SignatureOutlined,
} from "@ant-design/icons";

import "./index.scss";

export default function Receipts() {
  const profileData = JSON.parse(localStorage.getItem("profileData"));
  const { Dragger } = Upload;
  const formDataCheck = new FormData();
  const [fileList, setFileList] = useState([]);
  const offset = useRef(0);
  const limit = useRef(100);
  const propsCheck = {
    name: "receipt",
    multiple: false,
    accept: "application/pdf",
    fileList,
    beforeUpload(file) {
      setFileList([file]);
      return false;
    },
  };

  console.log(process.env.REACT_APP_BASE_URL);

  const props = {
    on: false,
    name: "receipt",
    multiple: false,
    accept: "application/pdf",
    customRequest({ file, onSuccess, onError }) {
      const formData = new FormData();
      formData.append("receipt", file);
      setFileList(file);
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL === 'https://dev-api.unitpay.io' ? 'https://dev-receipt.unitpay.io/api/v1/admin/receipt/analyze' : 'https://receipt.unitpay.io/api/v1/admin/receipt/analyze'}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setAnalyzeData(response.data);
          message.success(`${file.name} file uploaded successfully.`);
          onSuccess(response.data);
        })
        .catch((error) => {
          message.error(`${file.name} file upload failed.`);
          onError(error);
        });
    },
  };
  const [loading, setLoading] = useState(false);
  const [analyzeModal, setAnalyzeModal] = useState(false);
  const [addedReceiptModal, setAddedReceiptModal] = useState(false);
  const [checkReceiptModal, setCheckReceiptModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [valuePhone, setValuePhone] = useState();
  const [valueDate, setValueDate] = useState(null);
  const [addReceipt, setAddReceipt] = useState(false);
  const [analyzeData, setAnalyzeData] = useState(null);
  const [errorCheckMessage, setErrorCheckMessage] = useState("");
  const [updateReceipt, setUpdateReceipt] = useState(false);
  const [categoriesValues, setCategoriesValues] = useState({
    bank_name: "",
    example_type: "",
  });
  const [receiptValues, setReceiptValues] = useState({
    example_id: "",
    bank_name: "",
    order_amount: null,
    created_at: "",
    example_category_name: "",
    example_category_key: "",
    requisite_type: null,
    amount_key_before: "",
    amount_key_after: "",
    date_key_before: "",
    date_key_after: "",
    requisite_key_before: "",
    requisite_key_after: "",
    card_number: "",
    account_number: "",
    phone_number: "",
  });
  const [errors, setErrors] = useState({
    bank_name: false,
    example_type: false,
  });
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Банк",
      dataIndex: "bank",
      key: "bank_name",
      render: (bank) => bank?.bank_name,
    },
    {
      title: "Дата создания",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => moment(created_at).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Дата изменения",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (updated_at) =>
        updated_at
          ? moment(updated_at).format("YYYY-MM-DD HH:mm:ss")
          : "--- --- ---",
    },
    {
      title: "Действия",
      key: "actions",
      render: (id) => (
        <Flex align="center" gap={10}>
          <Popconfirm
            title="Удалить категорию"
            description="Вы уверены что хотите удалить категорию?"
            okText="Удалить"
            cancelText="Отмена"
            onConfirm={() => {
              handleDeleteCategory(id);
            }}
            className="custom-popconfirm"
          >
            <Tooltip placement="bottom" title="Удалить категорию">
              <Button
                danger
                type="primary"
                icon={<DeleteOutlined />}
                shape="circle"
              />
            </Tooltip>
          </Popconfirm>
          <Popconfirm
            title="Удалить чек"
            description="Вы уверены что хотите удалить чек из категории?"
            okText="Удалить"
            cancelText="Отмена"
            onConfirm={() => {
              handleDeleteReceiptCategory(id);
            }}
            className="custom-popconfirm"
          >
            <Tooltip placement="bottom" title="Удалить чек">
              <Button
                danger
                type="primary"
                icon={<SnippetsOutlined />}
                shape="circle"
              />
            </Tooltip>
          </Popconfirm>
          <Tooltip placement="bottom" title="Изменить чек">
            <Button
              type="primary"
              icon={<SignatureOutlined />}
              shape="circle"
              onClick={() => {
                openUpdateReceipt(id);
              }}
            />
          </Tooltip>
        </Flex>
      ),
    },
  ];
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    getCategories();
  }, []);

  const successNotice = (content) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };

  const errorNotice = (text) => {
    messageApi.open({
      type: "error",
      content: text,
    });
  };

  const getCategories = () => {
    setLoading(true);
    const formattedParams = formatParamsCategories();
    axios
      .get(`/api/v1/admin/receipt/`, {
        params: formattedParams,
      })
      .then((res) => {
        setCategories([])
        setCategories((prevCategories) => [...prevCategories, ...res?.data]);
        setLoading(false);
      })
      .catch((error) => {
        errorNotice(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const formatParamsCategories = () => {
    return {
      limit: limit.current,
      offset: offset.current,
      ...(categoriesValues?.bank_name &&
        categoriesValues.bank_name !== "" && {
          bank_name: categoriesValues.bank_name,
        }),
    };
  };

  const handleCancelanalyzeModal = () => {
    setAnalyzeModal(false);
    setFileList([]);
    setAnalyzeData(null);
  };

  const handleCheckReceiptModal = () => {
    setCheckReceiptModal(false);
    setUpdateReceipt(false);
    setReceiptValues({
      example_id: "",
      bank_name: "",
      order_amount: null,
      created_at: "",
      example_category_name: "",
      example_category_key: "",
      requisite_type: null,
      amount_key_before: "",
      amount_key_after: "",
      date_key_before: "",
      date_key_after: "",
      requisite_key_before: "",
      requisite_key_after: "",
      card_number: "",
      account_number: "",
      phone_number: "",
    });
    setValueDate(null);
    setValuePhone();
    setFileList([]);
    setErrorCheckMessage("");
    setAddReceipt(false)
  };

  const formatParamsReceipt = () => {
    const params = {
      bank_name: receiptValues.bank_name,
      order_amount: receiptValues.order_amount,
      example_category_name: receiptValues.example_category_name,
      example_category_key: receiptValues.example_category_key,
      requisite_type: receiptValues.requisite_type,
      amount_key_before: receiptValues.amount_key_before,
      amount_key_after: receiptValues.amount_key_after,
      date_key_before: receiptValues.date_key_before,
      date_key_after: receiptValues.date_key_after,
      requisite_key_before: receiptValues.requisite_key_before,
      requisite_key_after: receiptValues.requisite_key_after,
      created_at: moment(valueDate?.toString()).format("YYYY-MM-DDTHH:mm"),
    };

    if (receiptValues.card_number) {
      params.requisite = receiptValues.card_number;
    }

    if (valuePhone) {
      params.requisite = valuePhone;
    }

    if (receiptValues.account_number) {
      params.requisite = receiptValues.account_number;
    }

    return params;
  };

  const formatParamsReceiptUpdate = () => {
    const params = {
      example_id: receiptValues.example_id,
      bank_name: receiptValues.bank_name,
      order_amount: receiptValues.order_amount,
      requisite_type: receiptValues.requisite_type,
      amount_key_before: receiptValues.amount_key_before,
      amount_key_after: receiptValues.amount_key_after,
      date_key_before: receiptValues.date_key_before,
      date_key_after: receiptValues.date_key_after,
      requisite_key_before: receiptValues.requisite_key_before,
      requisite_key_after: receiptValues.requisite_key_after,
      created_at: moment(valueDate?.toString()).format("YYYY-MM-DDTHH:mm"),
    };

    if (receiptValues.card_number) {
      params.requisite = receiptValues.card_number;
    }

    if (valuePhone) {
      params.requisite = valuePhone;
    }

    if (receiptValues.account_number) {
      params.requisite = receiptValues.account_number;
    }

    return params;
  };

  const fetchAddReceipt = () => {
    const formattedParams = formatParamsReceipt();
    const queryParams = new URLSearchParams(formattedParams).toString();

    const formDataCheck = new FormData();
    if (fileList.length > 0) {
      formDataCheck.append("receipt", fileList[0]);
    }

    axios
      .post(`/api/v1/admin/receipt/add?${queryParams}`, formDataCheck, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        setCategories([]);
        offset.current = 0;
        getCategories();
        successNotice("Чек успешно добавлен!");
        handleCheckReceiptModal();
      })
      .catch((error) => {
        errorNotice(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const fetchCheckReceipt = () => {
    const formattedParams = formatParamsReceipt();
    const queryParams = new URLSearchParams(formattedParams).toString();

    const formDataCheck = new FormData();
    if (fileList.length > 0) {
      formDataCheck.append("receipt", fileList[0]);
    }

    axios
      .post(`/api/v1/admin/receipt/check?${queryParams}`, formDataCheck, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        if (res?.data?.message === null) {
          setAddReceipt(true);
          setErrorCheckMessage("");
        } else {
          setAddReceipt(false);
          setErrorCheckMessage(res?.data?.message);
        }
      })
      .catch((error) => {
        errorNotice(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const handleUpdateReceiptCategory = () => {
    const formattedParams = formatParamsReceiptUpdate();
    const queryParams = new URLSearchParams(formattedParams).toString();

    const formDataCheck = new FormData();
    if (fileList.length > 0) {
      formDataCheck.append("receipt", fileList[0]);
    }

    axios
      .patch(
        `/api/v1/admin/receipt/receipt_example/${receiptValues?.example_id}?${queryParams}`,
        formDataCheck,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(() => {
        setCategories([]);
        offset.current = 0;
        getCategories();
        successNotice("Чек успешно обновлен!");
        handleCheckReceiptModal();
      })
      .catch((error) => {
        errorNotice(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const handleDeleteCategory = (category) => {
    axios
      .delete(`/api/v1/admin/receipt/example_category/${category?.id}`)
      .then(() => {
        setCategories([]);
        offset.current = 0;
        getCategories();
        successNotice("Категория успешно удалена");
      })
      .catch((error) => {
        errorNotice(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const handleDeleteReceiptCategory = (category) => {
    axios
      .delete(
        `/api/v1/admin/receipt/receipt_example/${category?.receipt_examples[0]?.id}`
      )
      .then(() => {
        setCategories([]);
        offset.current = 0;
        getCategories();
        successNotice("Чек из категории успешно удален");
      })
      .catch((error) => {
        errorNotice(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const openUpdateReceipt = (category) => {
    setCheckReceiptModal(true);
    setUpdateReceipt(true);
    setReceiptValues({
      example_id: category?.id,
      bank_name: category?.bank?.bank_name,
      order_amount: null,
      example_category_name: "",
      example_category_key: "",
      requisite_type: category?.receipt_examples[0]?.requisite_type,
      amount_key_before: category?.receipt_examples[0]?.amount_key_before,
      amount_key_after: category?.receipt_examples[0]?.amount_key_after,
      date_key_before: category?.receipt_examples[0]?.date_key_before,
      date_key_after: category?.receipt_examples[0]?.date_key_after,
      requisite_key_before: category?.receipt_examples[0]?.requisite_key_before,
      requisite_key_after: category?.receipt_examples[0]?.requisite_key_after,
      card_number: "",
      account_number: "",
      phone_number: "",
    });
    // setValueDate(
    //   category?.receipt_examples[0]?.created_at
    //     ? new Date(category?.receipt_examples[0]?.created_at)
    //     : null
    // );
  };

  return (
    <div className="receiptsPage">
      {contextHolder}
      {/* Анализировать чек */}
      <Modal
        title="Анализировать чек"
        open={analyzeModal}
        cancelText="Отмена"
        okText="Анализировать"
        onCancel={handleCancelanalyzeModal}
        footer={false}
      >
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Область для загрузки</p>
          <p className="ant-upload-hint">
            Нажмите или перетащите файл в эту область, чтобы загрузить
          </p>
        </Dragger>
        {analyzeData !== null && (
          <div style={{ marginTop: 16 }}>
            <Flex gap={12} align="center">
              <h4>Кол-во ключей:</h4>
              <p>{analyzeData?.count_keys}</p>
            </Flex>
            <Flex gap={12} align="center">
              <h4>len xref values:</h4>
              <p>{analyzeData?.len_xref_values}</p>
            </Flex>
            <Flex gap={12} align="center">
              <h4>PDF версия:</h4>
              <p>{analyzeData?.pdf_version}</p>
            </Flex>
            <h4 style={{ marginTop: 12 }}>Ключи метадаты:</h4>
            <ul>
              {Object.entries(analyzeData?.metadata_keys || {}).map(
                ([key, value]) => (
                  <li key={key}>
                    {key}: {value}
                  </li>
                )
              )}
            </ul>
            <h4 style={{ marginTop: 12 }}>Текст:</h4>
            {/* <ul>
              {analyzeData?.text?.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul> */}
            {analyzeData?.text}
          </div>
        )}
      </Modal>

      {/* Проверить чек */}
      <Modal
        title={`${updateReceipt ? "Изменить" : "Проверить"} чек`}
        open={checkReceiptModal}
        onOk={fetchCheckReceipt}
        cancelText="Отмена"
        okText={`Проверить`}
        onCancel={handleCheckReceiptModal}
        footer={[
          <Button key="cancel" onClick={handleCheckReceiptModal}>
            Отмена
          </Button>,

          // Условный рендеринг кнопки "Проверить"
          !updateReceipt && (
            <Button
              key="check"
              type="primary"
              loading={loading}
              onClick={fetchCheckReceipt}
            >
              Проверить
            </Button>
          ),

          !updateReceipt ? (
            <Button
              key="add-update"
              disabled={!addReceipt}
              type="primary"
              loading={loading}
              onClick={fetchAddReceipt}
            >
              Добавить чек
            </Button>
          ) : (
            <Button
              key="add-update"
              type="primary"
              loading={loading}
              onClick={handleUpdateReceiptCategory}
            >
              Изменить чек
            </Button>
          ),
        ]}
      >
        <Flex vertical gap={12}>
          <Input
            style={{ width: "100%" }}
            placeholder="Введите название банка"
            value={receiptValues.bank_name}
            onChange={(e) => {
              setReceiptValues((prevValues) => ({
                ...prevValues,
                bank_name: e.target.value,
              }));
            }}
          />
          <Flex gap={16}>
            <DatePicker
              style={{ width: "100%" }}
              placeholder="Выберите дату и время создания"
              value={valueDate}
              onChange={setValueDate}
              format="dd.MM.yyyy HH:mm"
            />
          </Flex>
          <Flex gap={16}>
            <Input
              style={{ width: "100%" }}
              placeholder="Введите ключ даты до"
              value={receiptValues.date_key_before}
              onChange={(e) => {
                setReceiptValues((prevValues) => ({
                  ...prevValues,
                  date_key_before: e.target.value,
                }));
              }}
            />
            <Input
              style={{ width: "100%" }}
              placeholder="Введите ключ даты после"
              value={receiptValues.date_key_after}
              onChange={(e) => {
                setReceiptValues((prevValues) => ({
                  ...prevValues,
                  date_key_after: e.target.value,
                }));
              }}
            />
          </Flex>
          <Flex gap={16}>
            <InputNumber
              style={{ width: "100%" }}
              min={1}
              placeholder="Введите сумму"
              value={receiptValues.order_amount}
              onChange={(value) => {
                setReceiptValues((prevValues) => ({
                  ...prevValues,
                  order_amount: value,
                }));
              }}
            />
          </Flex>
          <Flex gap={16}>
            <Input
              style={{ width: "100%" }}
              placeholder="Введите ключ суммы до"
              value={receiptValues.amount_key_before}
              onChange={(e) => {
                setReceiptValues((prevValues) => ({
                  ...prevValues,
                  amount_key_before: e.target.value,
                }));
              }}
            />
            <Input
              style={{ width: "100%" }}
              placeholder="Введите ключ суммы после"
              value={receiptValues.amount_key_after}
              onChange={(e) => {
                setReceiptValues((prevValues) => ({
                  ...prevValues,
                  amount_key_after: e.target.value,
                }));
              }}
            />
          </Flex>
          {!updateReceipt && (
            <Flex gap={16}>
              <Input
                style={{ width: "100%" }}
                placeholder="Введите название категории"
                value={receiptValues.example_category_name}
                onChange={(e) => {
                  setReceiptValues((prevValues) => ({
                    ...prevValues,
                    example_category_name: e.target.value,
                  }));
                }}
              />
              <Input
                style={{ width: "100%" }}
                placeholder="Введите ключ категории"
                value={receiptValues.example_category_key}
                onChange={(e) => {
                  setReceiptValues((prevValues) => ({
                    ...prevValues,
                    example_category_key: e.target.value,
                  }));
                }}
              />
            </Flex>
          )}
          <Select
            style={{ width: "100%" }}
            placeholder="Выберите тип реквизитов"
            value={receiptValues.requisite_type}
            onChange={(value) => {
              setReceiptValues((prevValues) => ({
                ...prevValues,
                requisite_type: value,
                card_number: "",
                phone_number: "",
                account_number: "",
              }));
              setValuePhone();
            }}
            options={[
              {
                value: "CARD",
                label: "По картам",
              },
              {
                value: "PHONE",
                label: "СБП",
              },
              {
                value: "TRANSFER",
                label: "По счетам",
              },
            ]}
          />
          <Flex vertical gap={16}>
            {receiptValues?.requisite_type === "CARD" && (
              <Input
                style={{ width: "100%" }}
                placeholder="Введите номер карты"
                value={receiptValues.card_number}
                onChange={(e) => {
                  setReceiptValues((prevValues) => ({
                    ...prevValues,
                    card_number: e.target.value,
                  }));
                }}
              />
            )}
            {receiptValues?.requisite_type === "PHONE" && (
              <div className="phone__input">
                <PhoneInput
                  placeholder="Введите номер телефона"
                  value={valuePhone}
                  onChange={setValuePhone}
                  international
                />
              </div>
            )}
            {receiptValues?.requisite_type === "TRANSFER" && (
              <Input
                style={{ width: "100%" }}
                placeholder="Введите номер счета"
                value={receiptValues.account_number}
                onChange={(e) => {
                  setReceiptValues((prevValues) => ({
                    ...prevValues,
                    account_number: e.target.value,
                  }));
                }}
              />
            )}
          </Flex>
          {receiptValues.requisite_type && (
            <Flex gap={16}>
              <Input
                style={{ width: "100%" }}
                placeholder="Введите ключ реквизитов до"
                value={receiptValues.requisite_key_before}
                onChange={(e) => {
                  setReceiptValues((prevValues) => ({
                    ...prevValues,
                    requisite_key_before: e.target.value,
                  }));
                }}
              />
              <Input
                style={{ width: "100%" }}
                placeholder="Введите ключ реквизитов после"
                value={receiptValues.requisite_key_after}
                onChange={(e) => {
                  setReceiptValues((prevValues) => ({
                    ...prevValues,
                    requisite_key_after: e.target.value,
                  }));
                }}
              />
            </Flex>
          )}
          <Dragger {...propsCheck}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">Область для загрузки</p>
            <p className="ant-upload-hint">
              Нажмите или перетащите файл в эту область, чтобы загрузить
            </p>
          </Dragger>
          {errorCheckMessage !== "" && (
            <p className="error_text_message">{errorCheckMessage}</p>
          )}
        </Flex>
      </Modal>
      <div className="receipts__wrapper">
        <Flex gap={16} justify="space-between">
          <Flex gap={16}>
            <Input
              status={errors.bank_name ? "error" : ""}
              style={{ width: "270px" }}
              placeholder="Введите название банка"
              onChange={(e) => {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  bank_name: false,
                }));

                setCategoriesValues((prevValues) => ({
                  ...prevValues,
                  bank_name: e.target.value,
                }));
              }}
            />
            <Button
              onClick={getCategories}
              icon={<SearchOutlined />}
              type="primary"
            >
              Найти категории
            </Button>
          </Flex>
          <Flex gap={16}>
            <Button
              onClick={() => {
                setAnalyzeModal(true);
              }}
              type="primary"
              shape="round"
              icon={<FundViewOutlined />}
            >
              Анализировать чек
            </Button>
            {profileData?.role === "superadmin" && (
              <Button
                onClick={() => {
                  setCheckReceiptModal(true);
                }}
                type="primary"
                shape="round"
                icon={<FileProtectOutlined />}
              >
                Проверить чек
              </Button>
            )}
          </Flex>
        </Flex>
        <Table
          dataSource={categories}
          columns={columns}
          rowKey="id"
          pagination={false}
          loading={loading}
          expandable={{
            expandedRowRender: (record) => (
              <div
                style={{
                  paddingLeft: 24,
                }}
              >
                <h3>Ключевые слова:</h3>
                <ul
                  style={{
                    margin: 0,
                  }}
                >
                  {record?.key_words?.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            ),
            rowExpandable: (record) => record.name !== "Not Expandable",
          }}
        />
        <Flex align="center" justify="center" gap={20}>
          <Button
            disabled={100 > categories?.length}
            onClick={() => {
              setLoading(true);
              offset.current = offset.current + limit.current;
              getCategories();
            }}
            style={{
              paddingLeft: 40,
              paddingRight: 40,
              borderRadius: 15,
              marginTop: 15,
            }}
            type="primary"
            size="small"
            loading={loading}
          >
            Загрузить еще
            <ArrowDownOutlined />
          </Button>
        </Flex>
      </div>
    </div>
  );
}
