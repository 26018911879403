import Cookies from "js-cookie";

const authMiddleware = () => (next) => async (action) => {
  if (
    action?.meta?.arg?.endpointName !== "login" &&
    action?.meta?.arg?.endpointName !== undefined &&
    !Cookies.get("zalupa_key")
  ) {
    localStorage.removeItem("refresh_token_admin");
    localStorage.removeItem("unitrade_admin_auth");
    localStorage.removeItem("token_expiration");
    localStorage.removeItem("profileData");
    window.location.href = "https://service.unitpay.io/login";
  }
  const { error, payload } = action;

  if (error) {
    if (payload?.status === 401) {
      localStorage.removeItem("refresh_token_admin");
      localStorage.removeItem("unitrade_admin_auth");
      localStorage.removeItem("token_expiration");
      localStorage.removeItem("profileData");
      Cookies.remove("zalupa_key");
      setTimeout(() => {
        Cookies.remove("zalupa_key");
        localStorage.removeItem("unitrade_admin_auth");
        localStorage.removeItem("token_expiration");
        window.location.href = "https://service.unitpay.io/login";
      }, 800);
    }
  }

  return next(action);
};

export default authMiddleware;
