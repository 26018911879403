import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Button,
  Flex,
  Tooltip,
  Popconfirm,
  Modal,
  Card,
  Result,
  message,
  Input,
} from "antd";
import axios from "../axios";
import {
  ArrowDownOutlined,
  DeleteOutlined,
  KeyOutlined,
  UserAddOutlined,
  SignatureOutlined,
} from "@ant-design/icons";
import moment from "moment";

import "./index.scss";

export default function Users() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingGenerateFA, setLoadingGenerateFA] = useState(false);
  const [createUserModal, setCreateUserModal] = useState(false);
  const [changeFAModal, setСhangeFAModal] = useState(false);
  const [activeUser, setActiveUser] = useState("user");
  const [success, setSuccess] = useState(false);
  const [userData, setUserData] = useState(null);
  const { Meta } = Card;
  const offset = useRef(0);
  const limit = 100;
  const [FAValues, setFAValues] = useState({
    fa_key: "",
    sdk_key: "",
  });
  const [errors, setErrors] = useState({
    fa_key: false,
    sdk_key: false,
  });
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Логин",
      dataIndex: "login",
      key: "login",
    },
    {
      title: "Роль",
      dataIndex: "role",
      key: "role",
      render: (role) =>
        role === 1 ? "Суперадмин" : role === 2 ? "Админ" : "Пользователь",
    },
    {
      title: "Дата создания",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => moment(created_at).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Дата изменения",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (updated_at) =>
        updated_at
          ? moment(updated_at).format("YYYY-MM-DD HH:mm:ss")
          : "--- --- ---",
    },
    {
      title: "Действия",
      key: "actions",
      render: (id) => (
        <Flex align="center" gap={10}>
          <Popconfirm
            title="Удалить пользователя"
            description="Вы уверены что хотите удалить пользователя?"
            okText="Удалить"
            cancelText="Отмена"
            onConfirm={() => {
              confirmDelete(id);
            }}
            className="custom-popconfirm"
          >
            <Tooltip placement="bottom" title="Удалить пользователя">
              <Button
                danger
                type="primary"
                icon={<DeleteOutlined />}
                shape="circle"
              />
            </Tooltip>
          </Popconfirm>
          <Tooltip placement="bottom" title="Изменить 2FA аутентификацию">
            <Button
              onClick={() => {
                showChangeFAModal(id);
              }}
              type="primary"
              icon={<KeyOutlined />}
              shape="circle"
            />
          </Tooltip>
          <Popconfirm
            title="Изменить SDK KEY"
            description="Вы уверены что хотите изменить SDK KEY?"
            okText="Изменить"
            cancelText="Отмена"
            onConfirm={() => {
              confirmEditSDK(id);
            }}
            className="custom-popconfirm"
          >
            <Tooltip placement="bottom" title="Изменить SDK KEY">
              <Button
                type="primary"
                icon={<SignatureOutlined />}
                shape="circle"
              />
            </Tooltip>
          </Popconfirm>
        </Flex>
      ),
    },
  ];
  const [messageApi, contextHolder] = message.useMessage();

  const successNotice = () => {
    messageApi.open({
      type: "success",
      content: "SDK ключ пользователя успешно изменен!",
    });
  };

  const errorNotice = (text) => {
    messageApi.open({
      type: "error",
      content: text,
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    const formattedParams = formatParamsUsers();
    axios
      .get(`/api/v1/user/`, {
        params: formattedParams,
      })
      .then((res) => {
        setUsers((prevUsers) => [...prevUsers, ...res?.data]);
        setLoading(false);
      })
      .catch((error) => {
        errorNotice(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const formatParamsUsers = () => {
    return {
      limit: limit,
      offset: offset.current,
    };
  };

  const confirmDelete = (user) => {
    setLoading(true);
    axios
      .delete(`/api/v1/user/${user?.id}`)
      .then(() => {
        setUsers([]);
        offset.current = 0;
        getUsers();
      })
      .catch((error) => {
        errorNotice(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const confirmEditSDK = (user) => {
    setLoading(true);
    axios
      .patch(`/api/v1/user/update_key/${user?.id}`)
      .then(() => {
        setUsers([]);
        offset.current = 0;
        successNotice();
        getUsers();
      })
      .catch((error) => {
        errorNotice(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const showCreateUserModal = () => {
    setCreateUserModal(true);
  };

  const showChangeFAModal = (user) => {
    setUserData(user);
    setСhangeFAModal(true);
  };

  const handleCancelCreateModal = () => {
    setCreateUserModal(false);
    setActiveUser("user");
    setSuccess(false);
  };

  const handleCancelChangeFAModal = () => {
    setСhangeFAModal(false);
    setSuccess(false);
  };

  const fetchCreateUser = () => {
    setLoading(true);
    const body = {
      role: activeUser,
    };
    axios
      .post(`/api/v1/user/create`, body)
      .then(() => {
        setUsers([]);
        offset.current = 0;
        setLoading(false);
        setSuccess(true);
        getUsers();
        setTimeout(() => {
          handleCancelCreateModal();
        }, 1000);
      })
      .catch((error) => {
        errorNotice(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const fetchGenerateFAKey = () => {
    setLoadingGenerateFA(true);
    axios
      .get(`/api/v1/user/generate_fa_key/`)
      .then((res) => {
        setErrors((prevErrors) => ({
          ...prevErrors,
          fa_key: false,
        }));
        setFAValues((prevValues) => ({
          ...prevValues,
          fa_key: res?.data?.fa_key,
        }));
        setLoadingGenerateFA(false);
      })
      .catch((error) => {
        errorNotice(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const fetchChangeFAUser = () => {
    if (FAValues?.sdk_key?.length >= 3 && FAValues?.fa_key !== "") {
      setLoading(true);
      const body = {
        fa_key: FAValues.fa_key,
        login: userData?.login,
        sdk_key: FAValues.sdk_key,
      };
      axios
        .patch(`/api/v1/user/${userData?.id}`, body)
        .then(() => {
          setUsers([]);
          offset.current = 0;
          setLoading(false);
          setSuccess(true);
          getUsers();
          setTimeout(() => {
            handleCancelChangeFAModal();
          }, 1000);
        })
        .catch((error) => {
          errorNotice(error?.response?.data?.message);
          setLoading(false);
        });
    } else {
      if (FAValues.fa_key === "") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          fa_key: true,
        }));
      }
      if (FAValues.sdk_key?.length < 3) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          sdk_key: true,
        }));
      }
    }
  };

  return (
    <div className="receiptUsers">
      {contextHolder}
      {/* Создать пользователя */}
      <Modal
        loading={loading}
        title="Создать пользователя"
        open={createUserModal}
        onOk={fetchCreateUser}
        cancelText="Отмена"
        okText="Создать"
        onCancel={handleCancelCreateModal}
        footer={success ? null : undefined}
      >
        {!success ? (
          <Flex gap={16}>
            <Card
              onClick={() => {
                setActiveUser("user");
              }}
              hoverable
              style={{
                width: "50%",
                border:
                  activeUser === "user"
                    ? "1.5px solid #52C41A"
                    : "1.5px solid transparent",
                opacity: activeUser === "user" ? "1" : "0.6",
              }}
              cover={
                <img
                  height={220}
                  alt="example"
                  src="/assets/images/user-card.svg"
                />
              }
            >
              <Meta title="Пользователь" />
            </Card>
            <Card
              onClick={() => {
                setActiveUser("admin");
              }}
              hoverable
              style={{
                width: "50%",
                border:
                  activeUser === "admin"
                    ? "1.5px solid #52C41A"
                    : "1.5px solid transparent",
                opacity: activeUser === "admin" ? "1" : "0.6",
              }}
              cover={
                <img
                  height={220}
                  alt="example"
                  src="/assets/images/admin-card.svg"
                />
              }
            >
              <Meta title="Админ" />
            </Card>
          </Flex>
        ) : (
          <Result
            status="success"
            title={`${
              activeUser === "user" ? "Пользователь" : "Админ"
            } успешно создан!`}
            subTitle={`Вы успешно создали нового ${
              activeUser === "user" ? "пользователя" : "админа"
            }!`}
          />
        )}
      </Modal>
      {/* Изменить 2fa */}
      <Modal
        loading={loading}
        title="Изменить 2fa пользователя"
        open={changeFAModal}
        onOk={fetchChangeFAUser}
        cancelText="Отмена"
        okText="Изменить"
        onCancel={handleCancelChangeFAModal}
        footer={success ? null : undefined}
      >
        {!success ? (
          <>
            <Button
              loading={loadingGenerateFA}
              onClick={fetchGenerateFAKey}
              style={{ backgroundColor: "#52C41A", width: "100%" }}
              type="primary"
            >
              Сгенерировать новый 2FA ключ
            </Button>
            <Input
              status={errors.fa_key ? "error" : ""}
              style={{ width: "100%", marginTop: 12 }}
              placeholder="Сгенерируйте новый 2FA ключ"
              value={FAValues.fa_key}
              disabled
            />
            <Input
              status={errors.sdk_key ? "error" : ""}
              style={{ width: "100%", marginTop: 12 }}
              placeholder="Введите SDK ключ"
              onChange={(e) => {
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  sdk_key: false,
                }));
                setFAValues((prevValues) => ({
                  ...prevValues,
                  sdk_key: e.target.value,
                }));
              }}
            />
          </>
        ) : (
          <Result
            status="success"
            title={`2FA ключ успешно изменен!`}
            subTitle={`Вы успешно изменили 2FA ключ пользователя!`}
          />
        )}
      </Modal>
      <Button
        onClick={showCreateUserModal}
        style={{ backgroundColor: "#52C41A" }}
        type="primary"
        icon={<UserAddOutlined />}
      >
        Создать пользователя
      </Button>
      <Table
        dataSource={users}
        columns={columns}
        rowKey="id"
        pagination={false}
        loading={loading}
      />
      <Flex align="center" justify="center" gap={20}>
        <Button
          disabled={limit > users?.length}
          onClick={() => {
            setLoading(true);
            offset.current = offset.current + limit;
            getUsers();
          }}
          style={{
            paddingLeft: 40,
            paddingRight: 40,
            borderRadius: 15,
            marginTop: 15,
          }}
          type="primary"
          size="small"
          loading={loading}
        >
          Загрузить еще
          <ArrowDownOutlined />
        </Button>
      </Flex>
    </div>
  );
}
