import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const unitradeApi = createApi({
  reducerPath: "unitrade/api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("unitrade_admin_auth");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
        headers.set("time", String(Date.now()));
      }

      return headers;
    },
  }),
  keepUnusedDataFor: 0.0001,
  endpoints: (build) => ({
    login: build.query({
      query: (body) => ({
        url: `/api/v1/admin/auth/sign-in`,
        body,
        method: "POST",
      }),
    }),
    logout: build.query({
      query: (body) => ({
        url: `/api/v1/refresh-logout/sign-out`,
        body,
        method: "POST",
      }),
    }),
    getCurrencies: build.mutation({
      query: () => ({
        url: `/api/v1/admin/currencies/`,
        method: "GET",
      }),
    }),
    createCurrency: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/currencies/`,
        body,
        method: "POST",
      }),
    }),
    editedCurrency: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/currencies/${body?.id}`,
        body,
        method: "PUT",
      }),
    }),
    deletedCurrency: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/currencies/${body?.currency_id}`,
        body,
        method: "DELETE",
      }),
    }),
    getUsers: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/users/?${params}`,
        method: "GET",
      }),
    }),
    getUserSearch: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/users/${body?.user_id}`,
        method: "GET",
      }),
    }),
    getUser: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/users/${params}`,
        method: "GET",
      }),
    }),
    editedUUIDUser: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/users/update_uuid/${body?.id}`,
        method: "GET",
      }),
    }),
    createUser: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/users/create_user?role=${body?.role}`,
        body,
        method: "POST",
      }),
    }),
    editedUser: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/users/${body?.id}`,
        body,
        method: "PUT",
      }),
    }),
    deletedUser: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/users/${body?.user_id}`,
        method: "DELETE",
      }),
    }),
    getMerchantWallets: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/merchant-wallets/users/${body?.user_id}`,
        method: "GET",
      }),
    }),
    getMerchantWalletsByWalletId: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/merchant-wallets/${params}`,
        method: "GET",
      }),
    }),
    deleteMerchantWallets: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/merchant-wallets/${body?.wallet_id}`,
        method: "DELETE",
      }),
    }),
    createMerchantWallets: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/merchant-wallets/`,
        body,
        method: "POST",
      }),
    }),
    editMerchantWallets: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/merchant-wallets/${body?.wallet_id}`,
        body,
        method: "PUT",
      }),
    }),
    addDepositMerchantWallets: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/merchant-wallets/add-balance/${body?.wallet_id}`,
        body,
        method: "PATCH",
      }),
    }),
    subDepositMerchantWallets: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/merchant-wallets/substract-deposit/${body?.wallet_id}`,
        body,
        method: "PATCH",
      }),
    }),
    getPaymentMethods: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/payment-methods/?${params}`,
        method: "GET",
      }),
    }),
    getPaymentMethodsSearch: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/payment-methods/${body?.payment_id}`,
        method: "GET",
      }),
    }),
    createPaymentMethods: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/payment-methods/`,
        body,
        method: "POST",
      }),
    }),
    deletePaymentMethod: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/payment-methods/${body?.payment_id}`,
        method: "DELETE",
      }),
    }),
    editPaymentMethod: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/payment-methods/${body?.payment_id}`,
        body,
        method: "PUT",
      }),
    }),
    getBankBins: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/bank-bins/?${params}`,
        method: "GET",
      }),
    }),
    getBankBinsSearch: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/bank-bins/${body?.bin_id}/`,
        method: "GET",
      }),
    }),
    createBankBins: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/bank-bins/`,
        body,
        method: "POST",
      }),
    }),
    deleteBankBin: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/bank-bins/${body?.bin_id}/`,
        method: "DELETE",
      }),
    }),
    editBankBin: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/bank-bins/${body?.bin_id}/`,
        body,
        method: "PUT",
      }),
    }),
    getTradersData: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/trader-data/${body?.user_id}`,
      }),
    }),
    editTraderData: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/trader-data/${body?.trader_data_id}`,
        body,
        method: "PUT",
      }),
    }),
    getSmsMessages: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/sms-messages/?${params}`,
        method: "GET",
      }),
    }),
    getSmsMessagesSearch: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/sms-messages/${body?.message_id}`,
        method: "GET",
      }),
    }),
    getSmsHistory: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/sms-history/?${params}`,
        method: "GET",
      }),
    }),
    getSmsHistorySearch: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/sms-history/${body?.sms_history_id}/`,
        method: "GET",
      }),
    }),
    getOrders: build.mutation({
      query: (params) => ({
        url: `/api/v2/admin/orders/?${params}`,
        method: "GET",
      }),
    }),
    getRejectOrders: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/reject-orders/?${params}`,
        method: "GET",
      }),
    }),
    getOrderSearch: build.mutation({
      query: (body) => ({
        url: `/api/v2/admin/orders/search/${body?.order_id}`,
        method: "GET",
      }),
    }),
    getRejectOrderSearch: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/reject-orders/search/${body?.order_id}`,
        method: "GET",
      }),
    }),
    setOrderAppeal: build.mutation({
      query: ({ order_id, callback_flag, formData }) => {
        const config = {
          url: `/api/v2/admin/orders/appeal/${order_id}?callback_flag=${callback_flag}`,
          method: "PATCH",
        };

        // Если formData не пустой, добавляем body
        if (
          formData &&
          formData instanceof FormData &&
          formData.has("admin_receipts")
        ) {
          config.body = formData;
        }

        return config;
      },
    }),
    sendOrderCallback: build.mutation({
      query: (body) => ({
        url: `/api/v2/admin/orders/send-callback/${body?.order_id}`,
        method: "GET",
      }),
    }),

    setOrderCancel: build.mutation({
      query: (body) => ({
        url: `/api/v2/admin/orders/arbitration/${body.get('order_id')}?status=${body.get('status')}`,
        body,
        method: "PATCH",
      }),
    }),
    setOrderRecalc: build.mutation({
      query: (body) => ({
        url: `/api/v2/admin/orders/arbitration/${localStorage.getItem(
          "order_id"
        )}?status=success`,
        body,
        method: "PATCH",
      }),
    }),

    getOrderBillingDetails: build.mutation({
      query: (body) => ({
        url: `/api/v2/admin/orders/billing/${body}`,
        method: "GET",
      }),
    }),
    getBillingOrder: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/crypto-wallets/billing/order/${body?.trader_id}/${body?.id}`,
        method: "GET",
      }),
    }),
    getOrderDetails: build.mutation({
      query: (body) => ({
        url: `/api/v2/admin/orders/${body}`,
        method: "GET",
      }),
    }),
    getAnnouncements: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/announcements/?${params}`,
        method: "GET",
      }),
    }),
    getAnnouncementsSearch: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/announcements/${body?.ad_id}`,
        method: "GET",
      }),
    }),
    deleteAnnouncement: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/announcements/${params?.ad_id}`,
        method: "DELETE",
      }),
    }),
    editAnnouncement: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/announcements/${body?.id}`,
        body,
        method: "PUT",
      }),
    }),
    getAds: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/announcements/${params ? `?${params}` : ""}`,
        method: "GET",
      }),
    }),
    getCryptoWallets: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/crypto-wallets/${body.wallet_id}`,
        method: "GET",
      }),
    }),
    addDepositToWallet: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/crypto-wallets/add-deposit/${body?.wallet_id}`,
        body,
        method: "PATCH",
      }),
    }),
    addInputLimitToTrader: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/trader-data/add-input-limit/${body?.trader_data_id}`,
        body,
        method: "PATCH",
      }),
    }),
    addOutputLimitToTrader: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/trader-data/add-output-limit/${body?.trader_data_id}`,
        body,
        method: "PATCH",
      }),
    }),
    substractInputLimitToTrader: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/trader-data/substract-input-limit/${body?.trader_data_id}`,
        body,
        method: "PATCH",
      }),
    }),
    substractOutputLimitToTrader: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/trader-data/substract-output-limit/${body?.trader_data_id}`,
        body,
        method: "PATCH",
      }),
    }),
    substractDepositFromWallet: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/crypto-wallets/substract-deposit/${body?.wallet_id}`,
        body,
        method: "PATCH",
      }),
    }),
    addBalanceToWallet: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/crypto-wallets/add-balance/${body?.wallet_id}`,
        body,
        method: "PATCH",
      }),
    }),
    substractBalanceFromWallet: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/crypto-wallets/substract-balance/${body?.wallet_id}`,
        body,
        method: "PATCH",
      }),
    }),
    addFrozenToWallet: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/crypto-wallets/add-frozen-balance/${body?.wallet_id}`,
        body,
        method: "PATCH",
      }),
    }),
    substractFrozenFromWallet: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/crypto-wallets/substract-frozen-balance/${body?.wallet_id}`,
        body,
        method: "PATCH",
      }),
    }),
    AddCryptoWallet: build.mutation({
      query: () => ({
        url: `/api/v1/admin/profile/create-web3-wallet/`,
        method: "PATCH",
      }),
    }),
    connectCryptoWallet: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/profile/add-crypto-wallet/`,
        body,
        method: "PATCH",
      }),
    }),
    changeFaKeyUser: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/users/fa_key/${body?.id}`,
        body,
        method: "PATCH",
      }),
    }),
    changeFaKeyProfile: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/profile/change_fa_key/`,
        body,
        method: "PATCH",
      }),
    }),
    merchantWalletsBilling: build.query({
      query: (params) => ({
        url: `/api/v1/admin/merchant-wallets/billing/${params.get(
          "wallet_id"
        )}?${params}`,
        method: "GET",
      }),
    }),
    cryptoWalletsBilling: build.query({
      query: (params) => ({
        url: `/api/v1/admin/crypto-wallets/billing/${params.get(
          "wallet_id"
        )}?${params}`,
        method: "GET",
      }),
    }),
    cryptoWalletsStatistics: build.query({
      query: (body) => ({
        url: `/api/v1/admin/crypto-wallets/billing/statistics/${body?.wallet_id}`,
        method: "GET",
      }),
    }),
    generateFaKey: build.query({
      query: () => ({
        url: `/api/v1/admin/users/generate_fa_key/`,
        method: "GET",
      }),
    }),
    generateFaKeyProfile: build.query({
      query: () => ({
        url: `/api/v1/admin/profile/generate_fa_key/`,
        method: "GET",
      }),
    }),
    getProfileInfo: build.mutation({
      query: () => ({
        url: `/api/v1/admin/profile/info/`,
        method: "GET",
      }),
    }),
    getProfileBilling: build.mutation({
      query: () => ({
        url: `/api/v1/admin/profile/crypto-wallet/`,
        method: "GET",
      }),
    }),
    getMerchantWalletStatistics: build.query({
      query: (body) => ({
        url: `/api/v1/admin/merchant-wallets/billing/statistics/${body?.wallet_id}`,
        method: "GET",
      }),
    }),
    getSmsBilling: build.query({
      query: (body) => ({
        url: `/api/v1/admin/sms-messages/billing/${body}`,
        method: "GET",
      }),
    }),
    getStatistics: build.mutation({
      query: (params) => ({
        url: `/api/v2/admin/orders/statistics/?${params}`,
        method: "GET",
      }),
    }),
    getDevices: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/devices/?${params}`,
        method: "GET",
      }),
    }),
    getDevice: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/devices/${body?.device_id}`,
        method: "GET",
      }),
    }),
    addMinLimitAd: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/announcements/add-min-amount/${body?.ad_id}`,
        body,
        method: "PATCH",
      }),
    }),
    subMinLimitAd: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/announcements/substract-min-amount/${body?.ad_id}`,
        body,
        method: "PATCH",
      }),
    }),
    addMaxLimitAd: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/announcements/add-max-amount/${body?.ad_id}`,
        body,
        method: "PATCH",
      }),
    }),
    subMaxLimitAd: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/announcements/substract-max-amount/${body?.ad_id}`,
        body,
        method: "PATCH",
      }),
    }),
    redefineOrder: build.mutation({
      query: (body) => ({
        url: `/api/v2/admin/orders/redefine-orders/`,
        body,
        method: "PATCH",
      }),
    }),
    redefineTraderOrder: build.mutation({
      query: (body) => ({
        url: `/api/v2/admin/orders/redefine-orders/trader/`,
        body,
        method: "PATCH",
      }),
    }),
    redefineServiceTraderOrder: build.mutation({
      query: (body) => ({
        url: `/api/v2/admin/orders/redefine-orders/service-trader/`,
        body,
        method: "PATCH",
      }),
    }),
    statisticsOrders: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/statistics/orders/?${params}`,
        method: "GET",
      }),
    }),
    statisticsServiceOrders: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/statistics/orders/service-traders/?${params}`,
        method: "GET",
      }),
    }),
    avgCompleted: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/statistics/orders/avg-completed/?${params}`,
        method: "GET",
      }),
    }),
    cancelPercent: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/statistics/orders/common-cancel-percents/?${params}`,
        method: "GET",
      }),
    }),
    tradersKPIStatistics: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/users/traders-kpi/common-statistic/${body?.currency_id}`,
        method: "GET",
      }),
    }),
    serviceTradersKPIStatistics: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/users/traders-kpi/common-statistic/service-traders/${body?.currency_id}/`,
        method: "GET",
      }),
    }),
    tradersKPI: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/users/traders-kpi/traders/${
          body?.currency_id
        }?limit=${body?.limit}${
          body?.last_created_at !== null && body?.last_created_at !== undefined
            ? `&last_created_at=${body?.last_created_at}`
            : ""
        }`,
        method: "GET",
      }),
    }),
    serviceTradersKPI: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/users/traders-kpi/service-traders/${
          body?.currency_id
        }/?limit=${body?.limit}${
          body?.last_created_at !== null && body?.last_created_at !== undefined
            ? `&last_created_at=${body?.last_created_at}`
            : ""
        }`,
        method: "GET",
      }),
    }),
    traderKPIStatistic: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/users/traders-kpi/statistic/${
          body?.trader_id
        }?limit=${body?.limit}${
          body?.last_created_at !== null && body?.last_created_at !== undefined
            ? `&last_created_at=${body?.last_created_at}`
            : ""
        }`,
        method: "GET",
      }),
    }),
    traderServiceKPIStatistic: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/users/traders-kpi/statistic/service-traders/${
          body?.trader_id
        }/${body?.currency_id}?limit=${body?.limit}${
          body?.last_created_at !== null && body?.last_created_at !== undefined
            ? `&last_created_at=${body?.last_created_at}`
            : ""
        }`,
        method: "GET",
      }),
    }),
    linksTraders: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/link-merchants-traders/traders/${
          body?.merchant_id
        }?limit=${body?.limit}${
          body?.last_created_at !== null && body?.last_created_at !== undefined
            ? `&last_created_at=${body?.last_created_at}`
            : ""
        }`,
        method: "GET",
      }),
    }),
    linksMerchants: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/link-merchants-traders/merchants/${
          body?.trader_id
        }?limit=${body?.limit}${
          body?.last_created_at !== null && body?.last_created_at !== undefined
            ? `&last_created_at=${body?.last_created_at}`
            : ""
        }`,
        method: "GET",
      }),
    }),
    createLinkMerchantTrader: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/link-merchants-traders/`,
        body,
        method: "POST",
      }),
    }),
    createServiceLinkMerchantTrader: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/link-merchants-traders/service-traders/`,
        body,
        method: "POST",
      }),
    }),
    deleteLinkMerchantTrader: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/link-merchants-traders/${body?.merchant_id}/${body?.trader_id}`,
        method: "DELETE",
      }),
    }),
    editTraficByTrader: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/link-merchants-traders/${body?.link_id}`,
        body,
        method: "PATCH",
      }),
    }),
    activeWallet: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/crypto-wallets/wallet-display/${body?.wallet_id}`,
        method: "PATCH",
      }),
    }),
    activeDeposit: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/crypto-wallets/is-deposit/${body?.wallet_id}`,
        method: "PATCH",
      }),
    }),
    getReports: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/reports/?${params}`,
        method: "GET",
      }),
    }),
    getReportURL: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/reports/${body?.report_id}`,
        method: "GET",
      }),
    }),
    getClients: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/clients/?${params}`,
        method: "GET",
      }),
    }),
    banClient: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/clients/is-banned/${params?.id}`,
        method: "PATCH",
      }),
    }),
    createReport: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/reports/`,
        body,
        method: "POST",
      }),
    }),
    changeCryptoWallet: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/crypto-wallets/create-crypto-wallets/${body.wallet_id}`,
        body,
        method: "PATCH",
      }),
    }),
    getSuperadmins: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/profile/superadmins/?${params}`,
        method: "GET",
      }),
    }),
    createSuperadmin: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/profile/create-superuser/`,
        body,
        method: "POST",
      }),
    }),
    switchSuperadmin: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/profile/switch-profiles/${body.admin_id}`,
        body,
        method: "POST",
      }),
    }),
    editSuperadmin: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/profile/update-admin-data/`,
        body,
        method: "PATCH",
      }),
    }),
    massRedefineOrders: build.mutation({
      query: (body) => ({
        url: `/api/v2/admin/orders/mass-redefine-orders/trader/`,
        body,
        method: "PATCH",
      }),
    }),
    cancelPendingOrder: build.mutation({
      query: (body) => ({
        url: `/api/v2/admin/orders/cancel-sell-orders/`,
        body,
        method: "PATCH",
      }),
    }),
    rejectOrderStatistics: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/reject-orders/statistics/?${params}`,
        method: "GET",
      }),
    }),
    editMaxLimitPerHour: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/announcements/change-order-amount-per-hour/${body.ad_id}`,
        body,
        method: "PATCH",
      }),
    }),
    getServiceTraderCredentials: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/service-trader/credentials/${body.trader_id}/`,
        method: "GET",
      }),
    }),
    editTraderCredentials: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/service-trader/credentials/${body.object_id}/`,
        body,
        method: "PATCH",
      }),
    }),
    editTraderPasswords: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/service-trader/credentials/passwords/${body.object_id}`,
        body,
        method: "PATCH",
      }),
    }),
    getServiceTraderWallets: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/service-trader/wallets/users/${body.user_id}/`,
        method: "GET",
      }),
    }),
    createServiceTraderWallet: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/service-trader/wallets/`,
        body,
        method: "POST",
      }),
    }),
    deleteServiceTraderWallet: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/service-trader/wallets/${body?.wallet_id}/`,
        method: "DELETE",
      }),
    }),
    serviceTraderWalletDetails: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/service-trader/wallets/${body?.wallet_id}/`,
        method: "GET",
      }),
    }),
    addDepositServiceWallet: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/service-trader/wallets/add-deposit/${body?.wallet_id}/`,
        body,
        method: "PATCH",
      }),
    }),
    addBalanceServiceWallet: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/service-trader/wallets/add-balance/${body?.wallet_id}/`,
        body,
        method: "PATCH",
      }),
    }),
    addFrozenServiceWallet: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/service-trader/wallets/add-frozen-balance/${body?.wallet_id}/`,
        body,
        method: "PATCH",
      }),
    }),
    subDepositServiceWallet: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/service-trader/wallets/substract-deposit/${body?.wallet_id}/`,
        body,
        method: "PATCH",
      }),
    }),
    subBalanceServiceWallet: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/service-trader/wallets/substract-balance/${body?.wallet_id}/`,
        body,
        method: "PATCH",
      }),
    }),
    subFrozenServiceWallet: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/service-trader/wallets/substract-frozen-balance/${body?.wallet_id}/`,
        body,
        method: "PATCH",
      }),
    }),
    getBillingServiceWallet: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/service-trader/wallets/billing/${params.get(
          "wallet_id"
        )}?${params}`,
        method: "GET",
      }),
    }),
    getStatisticServiceWallet: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/service-trader/wallets/billing/statistics/${body?.wallet_id}`,
        method: "GET",
      }),
    }),
    getTransactions: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/transactions/?${params}`,
        method: "GET",
      }),
    }),
    getAvailableBanks: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/available-banks/?${params}`,
        method: "GET",
      }),
    }),
    linkAvailableBank: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/available-banks/`,
        body,
        method: "POST",
      }),
    }),
    deleteAvailableBank: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/available-banks/${body?.available_bank_id}`,
        method: "DELETE",
      }),
    }),
    editAvailableBank: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/available-banks/${body?.available_bank_id}`,
        body,
        method: "PATCH",
      }),
    }),
    getFullTraders: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/users/traders/?${params}`,
        method: "GET",
      }),
    }),
    getServiceTraderData: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/service-trader-data/${body?.service_trader_data_id}`,
        method: "GET",
      }),
    }),
    addInputLimitServiceTrader: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/service-trader-data/add-input-limit/${body?.service_trader_data_id}`,
        body,
        method: "PATCH",
      }),
    }),
    addOutputLimitServiceTrader: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/service-trader-data/add-output-limit/${body?.service_trader_data_id}`,
        body,
        method: "PATCH",
      }),
    }),
    subInputLimitServiceTrader: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/service-trader-data/substract-input-limit/${body?.service_trader_data_id}`,
        body,
        method: "PATCH",
      }),
    }),
    subOutputLimitServiceTrader: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/service-trader-data/substract-output-limit/${body?.service_trader_data_id}`,
        body,
        method: "PATCH",
      }),
    }),
    editTraderServiceData: build.mutation({
      query: (body) => ({
        url: `/api/v1/admin/service-trader-data/${body?.service_trader_data_id}`,
        body,
        method: "PATCH",
      }),
    }),
    getUsersByAgent: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/users/agents-users/${params.get(
          "agent_id"
        )}/?${params}`,
        method: "GET",
      }),
    }),
    getAvgUsdtStatistics: build.mutation({
      query: (params) => ({
        url: `/api/v1/admin/statistics/transactions/avg-usdt-price/?${params}`,
        method: "GET",
      }),
    }),
    getIdsOrders: build.mutation({
      query: (params) => ({
        url: `/api/v2/admin/orders/order_ids/?${params}`,
        method: "GET",
      }),
    }),
    getIdsTransactionOrders: build.mutation({
      query: (params) => ({
        url: `/api/v2/admin/orders/transaction_ids/?${params}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyLoginQuery,
  useLazyLogoutQuery,
  useLazyMerchantWalletsBillingQuery,
  useLazyCryptoWalletsBillingQuery,
  useLazyCryptoWalletsStatisticsQuery,
  useLazyGenerateFaKeyQuery,
  useLazyGenerateFaKeyProfileQuery,
  useGetCurrenciesMutation,
  useCreateCurrencyMutation,
  useEditedCurrencyMutation,
  useDeletedCurrencyMutation,
  useGetUsersMutation,
  useGetUserMutation,
  useGetStatisticsMutation,
  useGetUserSearchMutation,
  useCreateUserMutation,
  useEditedUserMutation,
  useDeletedUserMutation,
  useEditedUUIDUserMutation,
  useGetMerchantWalletsMutation,
  useGetMerchantWalletsByWalletIdMutation,
  useDeleteMerchantWalletsMutation,
  useCreateMerchantWalletsMutation,
  useEditMerchantWalletsMutation,
  useGetPaymentMethodsMutation,
  useGetPaymentMethodsSearchMutation,
  useCreatePaymentMethodsMutation,
  useDeletePaymentMethodMutation,
  useEditPaymentMethodMutation,
  useGetBankBinsMutation,
  useGetBankBinsSearchMutation,
  useCreateBankBinsMutation,
  useDeleteBankBinMutation,
  useEditBankBinMutation,
  useGetTradersDataMutation,
  useEditTraderDataMutation,
  useGetSmsMessagesMutation,
  useGetSmsMessagesSearchMutation,
  useGetSmsHistoryMutation,
  useGetSmsHistorySearchMutation,
  useGetOrdersMutation,
  useGetRejectOrdersMutation,
  useGetOrderSearchMutation,
  useGetRejectOrderSearchMutation,
  useSetOrderAppealMutation,
  useSendOrderCallbackMutation,
  useSetOrderCancelMutation,
  useSetOrderRecalcMutation,
  useGetOrderBillingDetailsMutation,
  useGetBillingOrderMutation,
  useGetOrderDetailsMutation,
  useGetAnnouncementsMutation,
  useGetAnnouncementsSearchMutation,
  useDeleteAnnouncementMutation,
  useEditAnnouncementMutation,
  useGetAdsMutation,
  useGetCryptoWalletsMutation,
  useAddDepositToWalletMutation,
  useAddInputLimitToTraderMutation,
  useAddOutputLimitToTraderMutation,
  useSubstractInputLimitToTraderMutation,
  useSubstractOutputLimitToTraderMutation,
  useAddBalanceToWalletMutation,
  useAddFrozenToWalletMutation,
  useSubstractDepositFromWalletMutation,
  useSubstractBalanceFromWalletMutation,
  useSubstractFrozenFromWalletMutation,
  useAddCryptoWalletMutation,
  useConnectCryptoWalletMutation,
  useChangeFaKeyUserMutation,
  useChangeFaKeyProfileMutation,
  useGetProfileInfoMutation,
  useGetProfileBillingMutation,
  useLazyGetMerchantWalletStatisticsQuery,
  useLazyGetSmsBillingQuery,
  useGetMerchantWalletsBillingMutation,
  useAddDepositMerchantWalletsMutation,
  useSubDepositMerchantWalletsMutation,
  useGetDevicesMutation,
  useGetDeviceMutation,
  useAddMinLimitAdMutation,
  useSubMinLimitAdMutation,
  useAddMaxLimitAdMutation,
  useSubMaxLimitAdMutation,
  useRedefineOrderMutation,
  useStatisticsOrdersMutation,
  useAvgCompletedMutation,
  useCancelPercentMutation,
  useTradersKPIStatisticsMutation,
  useServiceTradersKPIStatisticsMutation,
  useTradersKPIMutation,
  useServiceTradersKPIMutation,
  useTraderKPIStatisticMutation,
  useTraderServiceKPIStatisticMutation,
  useRejectOrderStatisticsMutation,
  useActiveWalletMutation,
  useActiveDepositMutation,
  useLinksTradersMutation,
  useLinksMerchantsMutation,
  useCreateLinkMerchantTraderMutation,
  useCreateServiceLinkMerchantTraderMutation,
  useDeleteLinkMerchantTraderMutation,
  useEditTraficByTraderMutation,
  useGetReportsMutation,
  useCreateReportMutation,
  useGetReportURLMutation,
  useGetClientsMutation,
  useBanClientMutation,
  useChangeCryptoWalletMutation,
  useRedefineTraderOrderMutation,
  useRedefineServiceTraderOrderMutation,
  useGetSuperadminsMutation,
  useCreateSuperadminMutation,
  useSwitchSuperadminMutation,
  useEditSuperadminMutation,
  useMassRedefineOrdersMutation,
  useEditMaxLimitPerHourMutation,
  useGetServiceTraderCredentialsMutation,
  useEditTraderCredentialsMutation,
  useEditTraderPasswordsMutation,
  useGetServiceTraderWalletsMutation,
  useCreateServiceTraderWalletMutation,
  useDeleteServiceTraderWalletMutation,
  useServiceTraderWalletDetailsMutation,
  useAddDepositServiceWalletMutation,
  useAddBalanceServiceWalletMutation,
  useAddFrozenServiceWalletMutation,
  useSubDepositServiceWalletMutation,
  useSubBalanceServiceWalletMutation,
  useSubFrozenServiceWalletMutation,
  useGetBillingServiceWalletMutation,
  useGetStatisticServiceWalletMutation,
  useGetTransactionsMutation,
  useGetAvailableBanksMutation,
  useLinkAvailableBankMutation,
  useDeleteAvailableBankMutation,
  useEditAvailableBankMutation,
  useGetFullTradersMutation,
  useStatisticsServiceOrdersMutation,
  useGetServiceTraderDataMutation,
  useAddInputLimitServiceTraderMutation,
  useAddOutputLimitServiceTraderMutation,
  useSubInputLimitServiceTraderMutation,
  useSubOutputLimitServiceTraderMutation,
  useEditTraderServiceDataMutation,
  useGetUsersByAgentMutation,
  useGetAvgUsdtStatisticsMutation,
  useCancelPendingOrderMutation,
  useGetIdsOrdersMutation,
  useGetIdsTransactionOrdersMutation,
} = unitradeApi;
