import React, { useEffect, useState } from "react";
import Header from "../../../Components/Header";
import { Link, Routes, Route, useLocation } from "react-router-dom";
import { ContainerOutlined, UserOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import PrivateRouteReceiptService from "../../../Components/PrivateRouteReceiptService";
import Users from "../Users";
import Receipts from "../Receipts";
import "./index.scss";
import Banks from "../Banks";

export default function Home() {
  const locataion = useLocation();
  const [currentTab, setCurrentTab] = useState("users");
  const profileData = JSON.parse(localStorage.getItem("profileData"));

  useEffect(() => {
    if(locataion?.pathname === '/receipt-service/receipts') {
      setCurrentTab("receipts");
    } else if(locataion?.pathname === '/receipt-service/banks') {
      setCurrentTab("banks");
    } else {
      setCurrentTab("users");
    }
  }, [locataion?.pathname])

  const items = [
    {
      label: <Link to="/receipt-service/users">Пользователи</Link>,
      key: "users",
      icon: <UserOutlined />,
    },
    {
      label: <Link to="/receipt-service/receipts">Чеки</Link>,
      key: "receipts",
      icon: <ContainerOutlined />,
    },
    {
      label: <Link to="/receipt-service/banks">Банки</Link>,
      key: "banks",
      icon: <ContainerOutlined />,
    },
  ];

  const itemsArbitr = [
    {
      label: <Link to="/receipt-service/receipts">Чеки</Link>,
      key: "receipts",
      icon: <ContainerOutlined />,
    },
    {
      label: <Link to="/receipt-service/banks">Банки</Link>,
      key: "banks",
      icon: <ContainerOutlined />,
    },
  ]

  const onClickTab = (e) => {
    setCurrentTab(e.key);
  };

  return (
    <div className="receiptHome container">
      <Header title={"Сервис чеков"} />
      <div className="receiptHome__wrapper">
        <div className="receiptHome__top">
          <Menu
            onClick={onClickTab}
            selectedKeys={[currentTab]}
            mode="horizontal"
            items={profileData?.role === 'superadmin' ? items : itemsArbitr}
          />
        </div>
        <Routes>
          <Route
            path="/users"
            element={<PrivateRouteReceiptService />}
          >
            <Route path="" element={<Users />} />
          </Route>
          <Route
            path="/receipts"
            element={<PrivateRouteReceiptService />}
          >
            <Route path="" element={<Receipts />} />
          </Route>
          <Route
            path="/banks"
            element={<PrivateRouteReceiptService />}
          >
            <Route path="" element={<Banks />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
}
