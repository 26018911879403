import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Button,
  Flex,
  Tooltip,
  Popconfirm,
  Modal,
  Card,
  Result,
  message,
  Input,
} from "antd";
import axios from "../axios";
import {
  ArrowDownOutlined,
  DeleteOutlined,
  KeyOutlined,
  SignatureOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import moment from "moment";

import "./index.scss";

export default function Banks() {
  const [banks, setBanks] = useState([]);
  const [bankName, setBankName] = useState("");
  const [loading, setLoading] = useState(true);
  const [createBankModal, setCreateBankModal] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [editBank, setEditBank] = useState(false);
  const [bankId, setBankId] = useState(null);
  const offset = useRef(0);
  const limit = 200;
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Название банка",
      dataIndex: "bank_name",
      key: "bank_name",
    },
    {
      title: "Дата создания",
      dataIndex: "created_at",
      key: "created_at",
      render: (created_at) => moment(created_at).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Действия",
      key: "actions",
      render: (id) => (
        <Flex align="center" gap={10}>
          <Popconfirm
            title="Удалить банк"
            description="Вы уверены что хотите удалить банк?"
            okText="Удалить"
            cancelText="Отмена"
            onConfirm={() => {
              confirmDeleteBank(id);
            }}
            className="custom-popconfirm"
          >
            <Tooltip placement="bottom" title="Удалить банк">
              <Button
                danger
                type="primary"
                icon={<DeleteOutlined />}
                shape="circle"
              />
            </Tooltip>
          </Popconfirm>
          <Tooltip placement="bottom" title="Изменить банк">
            <Button
              type="primary"
              icon={<SignatureOutlined />}
              shape="circle"
              onClick={() => {
                setBankName(id?.bank_name);
                setBankId(id?.id);
                setEditBank(true);
                showCreateBankModal();
              }}
            />
          </Tooltip>
        </Flex>
      ),
    },
  ];

  const successNotice = (text) => {
    messageApi.open({
      type: "success",
      content: text,
    });
  };

  const errorNotice = (text) => {
    messageApi.open({
      type: "error",
      content: text,
    });
  };

  useEffect(() => {
    getBanks();
  }, []);

  const formatParamsBanks = () => {
    return {
      limit: limit,
      offset: offset.current,
    };
  };

  const showCreateBankModal = () => {
    setCreateBankModal(true);
  };

  const handleCancelCreateModal = () => {
    setCreateBankModal(false);
    setBankName("");
    setBankId(null);
    setEditBank(false);
  };

  const getBanks = () => {
    const formattedParams = formatParamsBanks();
    axios
      .get(`/api/v1/admin/bank/`, {
        params: formattedParams,
      })
      .then((res) => {
        setBanks((prevBanks) => [...prevBanks, ...res?.data]);
        setLoading(false);
      })
      .catch((error) => {
        errorNotice(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const confirmDeleteBank = (bank) => {
    setLoading(true);
    axios
      .delete(`/api/v1/admin/bank/${bank?.id}`)
      .then(() => {
        setBanks([])
        offset.current = 0;
        getBanks();
      })
      .catch((error) => {
        errorNotice(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const fetchCreateBank = () => {
    setLoading(true);
    const body = {
      bank_name: bankName,
    };
    axios
      .post(`/api/v1/admin/bank/`, body)
      .then(() => {
        setBanks([])
        offset.current = 0;
        setLoading(false);
        getBanks();
        handleCancelCreateModal();
        successNotice("Банк успешно добавлен!");
      })
      .catch((error) => {
        errorNotice(error?.response?.data?.message);
        setLoading(false);
      });
  };

  const fetchEditBank = () => {
    setLoading(true);
    const body = {
      bank_name: bankName,
    };
    axios
      .patch(`/api/v1/admin/bank/${bankId}`, body)
      .then(() => {
        setBanks([])
        offset.current = 0;
        setLoading(false);
        getBanks();
        handleCancelCreateModal();
        successNotice("Банк успешно изменен!");
      })
      .catch((error) => {
        errorNotice(error?.response?.data?.message);
        setLoading(false);
      });
  };

  return (
    <div className="receiptBanks">
      {contextHolder}
      <Modal
        title={`${editBank ? "Изменить" : "Создать"} банк`}
        open={createBankModal}
        onCancel={handleCancelCreateModal}
        footer={[
          <Button key="cancel" onClick={handleCancelCreateModal}>
            Отмена
          </Button>,
          <Button
            key="create"
            type="primary"
            onClick={editBank ? fetchEditBank : fetchCreateBank}
            loading={loading}
            disabled={bankName?.length < 3}
          >
            {editBank ? 'Изменить' : 'Создать'}
          </Button>,
        ]}
      >
        <Input
          style={{ width: "100%" }}
          placeholder="Введите название банка"
          value={bankName}
          onChange={(e) => {
            setBankName(e.target.value);
          }}
        />
      </Modal>
      <Button
        onClick={showCreateBankModal}
        style={{ backgroundColor: "#52C41A" }}
        type="primary"
        icon={<UserAddOutlined />}
      >
        Создать банк
      </Button>
      <Table
        dataSource={banks}
        columns={columns}
        rowKey="id"
        pagination={false}
        loading={loading}
      />
      <Flex align="center" justify="center" gap={20}>
        <Button
          disabled={limit > banks?.length}
          onClick={() => {
            setLoading(true);
            offset.current = offset.current + limit;
            getBanks();
          }}
          style={{
            paddingLeft: 40,
            paddingRight: 40,
            borderRadius: 15,
            marginTop: 15,
          }}
          type="primary"
          size="small"
          loading={loading}
        >
          Загрузить еще
          <ArrowDownOutlined />
        </Button>
      </Flex>
    </div>
  );
}
