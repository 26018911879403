import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: false,
  navBar: true,
  theme: false,
  localization: false,
  profileData: null,
  currencies: [],
  paymentMethods: [],
  bankBins: [],
  users: [],
  smsMessages: [],
  selectedBanks: [],
  smsHistory: [],
  announcements: [],
  userWallet: null,
  cryptoWalletStatistics: null,
  traderData: null,
  orders: [],
  rejectOrders: [],
  orderData: null,
  user: null,
  merchantWallet: [],
  cryptoWalletBilling: [],
  merchantWalletBilling: [],
  merchantWalletStatistics: null,
  cryptoWallet: null,
  faKeyData: null,
  faKeyDataProfile: null,
  orderInfoData: null,
  statisticData: null,
  smsMessageBilling: [],
  billingOrder: null,
  devices: [],
  device: null,
  billingOrderHistory: [],
  profileBilling: null,
  statisticsOrders: null,
  statisticsRejectOrders: null,
  avgCompleted: null,
  cancelPercent: null,
  tradersKPICommonStatistics: null,
  reports: [],
  tradersKPIArray: [],
  usersMerchant: [],
  traderKPIStatistic: null,
  traderKPIStatisticArray: [],
  linkMerchant: null,
  linkTrader: null,
  linkTraders: [],
  linkMerchants: [],
  clients: [],
  superadminsData: [],
  serviceTraderCredentials: null,
  serviceTraderWallets: [],
  serviceTraderWalletDetails: null,
  serviceTraderWalletBilling: [],
  serviceTraderWalletStatistics: null,
  serviceUsers: [],
  transactions: [],
  availableBanks: [],
  fullTraders: [],
  serviceTraderData: null,
  usersByAgent: [],
  avgUsdt: null,
  usersAgent: [],
  roles: [
    {
      label: "Трейдер",
      value: "trader",
    },
    {
      label: "Агент",
      value: "agent",
    },
    {
      label: "Арбитр",
      value: "arbitr",
    },
    {
      label: "Мерчант",
      value: "merchant_server",
    },
    {
      label: "Сервисный трейдер",
      value: "service_trader",
    },
  ],
  baned: [
    {
      label: "Заблокировать",
      value: true,
    },
    {
      label: "Разблокировать",
      value: false,
    },
  ],
  activeData: [
    {
      label: "Активен",
      value: true,
    },
    {
      label: "Неактивен",
      value: false,
    },
  ],
  language: {
    general: {
      search_ru: "Поиск...",
      login_ru: "Войти",
      token_ru: "Токен",
      code_ru: "Код authenticator",
      total_orders_ru: "выполненных заявок:",
      total_amount_ru: "общий оборот в",
      filter_ru: "Фильтр",
      search_id_ru: "Поиск по id",
      search_amount_ru: "Поиск по сумме",
      search_card_ru: "Поиск по номеру карты",
      search_date_ru: "Поиск по дате",
      search_status_ru: "Поиск по статусу",
      recalculation_amount_ru: "Введите сумму перерасчета",
      recalculation_amount_label_ru: "Сумма перерасчета",
      total_orders_en: "of orders completed:",
      total_amount_en: "total turnover in",
      search_id_en: "Search by id",
      search_amount_en: "Search by amount",
      search_card_en: "Search by card number",
      search_date_en: "Search by date",
      search_status_en: "Search by status",
      recalculation_amount_en: "Enter recalculation amount",
      recalculation_amount_label_en: "Recalculation amount",
      filter_en: "Filter",
      search_en: "Search...",
      login_en: "Login in",
      token_en: "Token",
      code_en: "Authenticator code",
    },
    empty: {
      sell_empty_ru: "У вас нет активных заявок",
      sell_search_empty_ru: "Заявок не найдено",
      appeal_empty_ru: "У вас нет активных апелляций",
      appeal_search_empty_ru: "Апелляций не найдено",
      history_empty_ru: "У вас нет заявок в истории",
      history_search_empty_ru: "",
      ads_empty_ru: "У вас нет добавленных объявлений",
      ads_search_empty_ru: "Объявлений не найдено",
      sell_empty_en: `You don't have any active orders`,
      sell_search_empty_en: "No orders found",
      appeal_empty_en: `You don't have any active appeals`,
      appeal_search_empty_en: "No appeals found",
      history_empty_en: `You don't have any applications in your history`,
      history_search_empty_en: "",
      ads_empty_en: `You don't have any ads added`,
      ads_search_empty_en: "No ads found",
    },
    nav_bar: {
      profile_ru: "Профиль",
      sell_ru: "Продажа USDT",
      buy_ru: "Покупка USDT",
      history_ru: "История заявок",
      appeal_ru: "Аппеляции",
      ads_ru: "Объявления",
      automatic_ru: "Автоматика",
      settings_ru: "Настройки",
      wallet_ru: "Кошелек",
      details_ru: "Детали заявки",
      logout_ru: "Выйти",
      profile_en: "Profile",
      sell_en: "Sell USDT",
      buy_en: "Buy USDT",
      history_en: "Orders history",
      appeal_en: "Appeals",
      ads_en: "Ads",
      automatic_en: "Automation",
      settings_en: "Settings",
      wallet_en: "Сurrencies",
      details_en: "Order details",
      logout_en: "Log Out",
    },
    order: {
      requisites_ru: "Реквизиты",
      sender_ru: "Отправитель",
      receive_details_ru: "К получению",
      receive_ru: "Получаем",
      receive_or_sending_ru: "Получаем/Отправляем",
      sending_details_ru: "К отправлению",
      sending_ru: "Отправляем",
      recipient_ru: "Получатель",
      recipient_or_sender_ru: "получатель/отправитель",
      created_date_ru: "Дата создания",
      completed_date_ru: "Дата завершения",
      actions_ru: "Действие",
      accept_order_ru: "Подтвердить заявку",
      accept_order_text_ru: "Подтвердите что вы перевели средства",
      accept_sell_order_ru: "Принять заявку",
      accept_sell_order_text_ru: "Вы уверены что хотите принять заявку?",
      accept_sell_order_btn_ru: "Принять",
      accept_order_btn_ru: "Подтвердить",
      cancel_order_ru: "Отменить заявку",
      cancel_order_text_ru: "Вы уверены что хотите отменить заявку?",
      cancel_order_btn_ru: "Отменить заявку",
      recalculation_order_ru: "Перерасчет",
      recalculation_order_text_ru: "Вы уверены что хотите сделать перерасчет?",
      recalculation_order_btn_ru: "Перерасчитать",
      file_order_text_ru: "Прикрепить (чек, выписку...)",
      files_details_text_ru: "Прикрепленные файлы",
      files_empty_text_ru: "Нет прикрепленных файлов",
      status_ru: "Статус",
      rating_ru: "Рейтинг",
      requisites_en: "Requisites",
      sender_en: "Sender",
      receive_en: "Receive",
      sending_en: "Sending",
      recipient_en: "Recipient",
      recipient_or_sender_en: "Recipient/\nSender",
      receive_or_sending_en: "Receive/\nSending",
      created_date_en: "Created date",
      completed_date_en: "Completed date",
      actions_en: "Actions",
      accept_order_en: "Confirm order",
      accept_order_text_en: "Confirm that you have transferred funds",
      accept_sell_order_en: "Accepted order",
      accept_sell_order_text_en: "Are you sure you want to accept the order?",
      accept_sell_order_btn_en: "Accept",
      accept_order_btn_en: "Confirm",
      cancel_order_en: "Rejected order",
      cancel_order_text_en: "Are you sure you want to cancel the order?",
      cancel_order_btn_en: "Reject order",
      recalculation_order_en: "Recalculation",
      recalculation_order_text_en: "Are you sure you want to recalculate?",
      recalculation_order_btn_en: "Recalculate",
      file_order_text_en: "Attach (receipt, statement...)",
      status_en: "Status",
      rating_en: "Rating",
      files_details_text_en: "Attached files",
      files_empty_text_en: "No attached files",
    },
    ads: {
      sell_ru: "Ввод",
      buy_ru: "Вывод",
      create_ad_ru: "Создать объявление",
      fee_ru: "Ставка",
      make_ads_ru: "Вкл/Выкл объявления",
      edit_ad_ru: "Редактировать объявление",
      type_ru: "Тип",
      payment_ru: "Способ оплаты",
      card_number_ru: "Номер карты",
      client_card_number_ru: "Номер карты клиента",
      card_holder_ru: "ФИО владельца карты",
      max_limit_ru: "Max лимит",
      min_limit_ru: "Min лимит",
      enter_card_number_ru: "Введите номер карты",
      enter_card_holder_ru: "Введите ФИО",
      enter_max_limit_ru: "Введите max лимит",
      enter_min_limit_ru: "Введите min лимит",
      enter_comment_ru: "Введите комментарий к объявлению",
      save_ru: "Сохранить",
      cancel_ru: "Отменить",
      delete_ru: "Удалить",
      deleted_ad_ru: "Удалить объявление",
      deleted_devise_ru: "Удалить девайс",
      delete_text_ru: "Вы уверены что хотите удалить объявление?",
      delete_text_devise_ru: "Вы уверены что хотите удалить девайс?",
      sell_en: "Sell",
      buy_en: "Buy",
      create_ad_en: "Created ad",
      fee_en: "Bet",
      make_ads_en: "On/Off ads",
      edit_ad_en: "Edited ad",
      type_en: "Type",
      payment_en: "Payment method",
      card_number_en: "Card number",
      client_card_number_en: "Client curd number",
      card_holder_en: "Card holder",
      max_limit_en: "Max limit",
      min_limit_en: "Min limit",
      enter_card_number_en: "Enter card number",
      enter_card_holder_en: "Enter card holder",
      enter_max_limit_en: "Enter max limit",
      enter_min_limit_en: "Enter min limit",
      enter_comment_en: "Enter a comment to the ad",
      save_en: "Save",
      cancel_en: "Cancel",
      delete_en: "Delete",
      deleted_ad_en: "Deleted ad",
      deleted_devise_en: "Deleted devise",
      delete_text_en: "Are you sure you want to delete the ad?",
      delete_text__devise_en: "Are you sure you want to delete the devise?",
    },
  },
};

const unitradeSlice = createSlice({
  name: "unitrade",
  initialState,
  reducers: {
    setLoader(state, action) {
      state.loader = action.payload;
    },
    setNavBar(state, action) {
      state.navBar = action?.payload;
    },
    setTheme(state, action) {
      state.theme = action?.payload;
    },
    setLocalization(state, action) {
      state.localization = action?.payload;
    },
    setLanguage(state, action) {
      state.language = action?.payload;
    },
    setCurrencies(state, action) {
      state.currencies = action?.payload;
    },
    setUsers(state, action) {
      const uniqueOrders = action?.payload.filter(
        (newOrder) =>
          !state.users.some(
            (existingOrder) => existingOrder.created_at === newOrder.created_at
          )
      );
      state.users = [...state.users, ...uniqueOrders];
    },
    setUserSearch(state, action) {
      state.users = action?.payload;
    },
    setUserFilter(state, action) {
      state.users = action?.payload;
    },
    setUserFilterMerchant(state, action) {
      state.usersMerchant = action?.payload;
    },
    setAgentUsers(state, action) {
      state.usersAgent = action?.payload;
    },
    setGenerateFaKey(state, action) {
      state.faKeyData = action?.payload;
    },
    setGenerateFaKeyProfile(state, action) {
      state.faKeyDataProfile = action?.payload;
    },
    setCreateUser(state, action) {
      state.users = [...state.users, action?.payload];
    },
    setDeleteUser(state, action) {
      const deletedUserId = action?.payload;
      console.log(deletedUserId);

      if (deletedUserId) {
        state.users = state.users.filter((user) => user?.id !== deletedUserId);
      }
    },
    setEditUser(state, action) {
      const targetId = action?.payload?.id;

      if (targetId) {
        state.users = state.users.map((user) => {
          if (user.id === targetId) {
            return {
              ...user,
              login: action?.payload?.login,
              channel_id: action?.payload?.channel_id,
              telegram_id: action?.payload?.telegram_id,
              user_url: action?.payload?.user_url,
              agent_id: action?.payload?.agent_id,
              main_merchant: action?.payload?.main_merchant,
              role: action?.payload?.role,
              ip_list: action?.payload?.ip_list,
              is_banned: action?.payload?.is_banned,
              is_service: action?.payload?.is_service,
              type_active: action?.payload?.type_active,
              agent_fee: action?.payload?.agent_fee,
              notice: action?.payload.notice,
              session_timeout: action?.payload.session_timeout,
            };
          } else {
            return user;
          }
        });
      }
    },
    setUserData(state, action) {
      state.user = action?.payload;
    },
    setMerchantWallet(state, action) {
      state.merchantWallet = action?.payload;
    },
    setMerchantWalletByWalletId(state, action) {
      state.userWallet = action?.payload;
    },
    setEditMerchantWallet(state, action) {
      const editedWallet = action.payload;
      const updatedMerchantWallets = state.merchantWallet.map((wallet) => {
        if (wallet.id === editedWallet.id) {
          return editedWallet;
        } else {
          return wallet;
        }
      });

      return {
        ...state,
        merchantWallet: updatedMerchantWallets,
      };
    },
    setDeleteMerchantWalletByWalletId(state, action) {
      const deletedMerchantWalletId = action?.payload?.wallet_id;
      if (deletedMerchantWalletId) {
        state.merchantWallet = state.merchantWallet.filter(
          (wallet) => wallet?.id !== deletedMerchantWalletId
        );
      }
    },
    setPaymentsMethods(state, action) {
      const uniqueOrders = action?.payload.filter(
        (newOrder) =>
          !state.paymentMethods.some(
            (existingOrder) => existingOrder.created_at === newOrder.created_at
          )
      );
      state.paymentMethods = [...state.paymentMethods, ...uniqueOrders];
    },
    setPaymentsMethodsAds(state, action) {
      let array = action?.payload.map((paymentMethod) => ({
        ...paymentMethod,
        label: paymentMethod.bank_name,
        value: paymentMethod.id,
      }));
      const uniqueOrders = array.filter(
        (newOrder) =>
          !state.paymentMethods.some(
            (existingOrder) => existingOrder.id === newOrder.id
          )
      );
      state.paymentMethods = [...state.paymentMethods, ...uniqueOrders];
    },
    setPaymentsMethodsAdsSearch(state, action) {
      state.paymentMethods = action?.payload;
    },
    setCreatePaymentMethod(state, action) {
      state.paymentMethods = [...state.paymentMethods, action?.payload];
    },
    setDeletePaymentMethod(state, action) {
      const deletedPaymentMethodId = action?.payload;

      if (deletedPaymentMethodId) {
        state.paymentMethods = state.paymentMethods.filter(
          (paymentMethod) => paymentMethod?.id !== deletedPaymentMethodId
        );
      }
    },
    setEditPaymentMethod(state, action) {
      const targetId = action?.payload?.id;

      if (targetId) {
        state.paymentMethods = state.paymentMethods.map((paymentMethod) => {
          if (paymentMethod.id === targetId) {
            return {
              ...paymentMethod,
              country: action?.payload?.country,
              bank_name: action?.payload?.bank_name,
              bank_id: action?.payload?.bank_id,
              is_active: action?.payload?.is_active,
              currency_id: action?.payload?.currency_id,
            };
          } else {
            return paymentMethod;
          }
        });
      }
    },
    setBankBins(state, action) {
      const uniqueOrders = action?.payload.filter(
        (newOrder) =>
          !state.bankBins.some(
            (existingOrder) => existingOrder.id === newOrder.id
          )
      );
      state.bankBins = [...state.bankBins, ...uniqueOrders];
    },
    setBankBinsSearch(state, action) {
      state.bankBins = action?.payload;
    },
    setCreateBankBins(state, action) {
      state.bankBins = [...state.bankBins, action?.payload];
    },
    setDeleteBankBins(state, action) {
      const deletedBankBinsId = action?.payload;

      if (deletedBankBinsId) {
        state.bankBins = state.bankBins.filter(
          (bankBins) => bankBins?.id !== deletedBankBinsId
        );
      }
    },
    setEditBankBins(state, action) {
      const targetId = action?.payload?.id;

      if (targetId) {
        state.bankBins = state.bankBins.map((bankBins) => {
          if (bankBins.id === targetId) {
            return {
              ...bankBins,
              bin_id: action?.payload?.bin_id,
              bin: action?.payload?.bin,
              payment_method_id: action?.payload?.payment_method_id,
            };
          } else {
            return bankBins;
          }
        });
      }
    },
    setTradersData(state, action) {
      state.traderData = action?.payload;
    },
    setEditTraderData(state, action) {
      const targetId = action?.payload?.id;

      if (targetId) {
        state.traderData = state.traderData.map((traderData) => {
          if (traderData.id === targetId) {
            return {
              ...traderData,
              trader_data: action?.payload?.trader_data,
              rate: action?.payload?.rate,
              input_fee: action?.payload?.input_fee,
              output_fee: action?.payload?.output_fee,
              daily_input_limit: action?.payload?.daily_input_limit,
              daily_output_limit: action?.payload?.daily_output_limit,
              input_limit: action?.payload?.input_limit,
              output_limit: action?.payload?.output_limit,
              currency_id: action?.payload?.currency_id,
            };
          } else {
            return traderData;
          }
        });
      }
    },
    setSmsMessages(state, action) {
      const uniqueOrders = action?.payload.filter(
        (newOrder) =>
          !state.smsMessages.some(
            (existingOrder) => existingOrder.id === newOrder.id
          )
      );
      state.smsMessages = [...state.smsMessages, ...uniqueOrders];
    },
    setSmsMessagesSearch(state, action) {
      state.smsMessages = action?.payload;
    },
    setSmsHistory(state, action) {
      const uniqueOrders = action?.payload.filter(
        (newOrder) =>
          !state.smsHistory.some(
            (existingOrder) => existingOrder.id === newOrder.id
          )
      );
      state.smsHistory = [...state.smsHistory, ...uniqueOrders];
    },
    setSmsHistorySearch(state, action) {
      state.smsHistory = action?.payload;
    },
    setOrders(state, action) {
      const uniqueOrders = action?.payload.filter(
        (newOrder) =>
          !state.orders.some(
            (existingOrder) => existingOrder.id === newOrder.id
          )
      );
      state.orders = [...state.orders, ...uniqueOrders];
    },
    setRejectOrders(state, action) {
      const uniqueOrders = action?.payload.filter(
        (newOrder) =>
          !state.rejectOrders.some(
            (existingOrder) => existingOrder.id === newOrder.id
          )
      );
      state.rejectOrders = [...state.rejectOrders, ...uniqueOrders];
    },
    setOrdersSearch(state, action) {
      state.orders = action?.payload;
    },
    setRejectOrdersSearch(state, action) {
      state.rejectOrders = action?.payload;
    },
    setOrdersEmpty(state, action) {
      state.orders = action?.payload;
    },
    setRejectOrdersEmpty(state, action) {
      state.rejectOrders = action?.payload;
    },
    setOrderData(state, action) {
      state.orderData = action?.payload;
    },
    setOrderInfoData(state, action) {
      state.orderInfoData = action?.payload;
    },
    setEditOrderAppeal(state, action) {
      const targetId = action?.payload?.id;

      if (targetId) {
        state.orders = state.orders.map((order) => {
          if (order.id === targetId) {
            // Возвращаем новый объект, используя расширение свойств (spread operator)
            return {
              ...order, // Копируем все свойства из текущего объекта
              ...action.payload, // Перезаписываем свойства нового объекта из action.payload
            };
          } else {
            return order;
          }
        });
      }
    },
    setDeleteOrder(state, action) {
      const targetId = action?.payload?.id;

      if (targetId) {
        state.orders = state.orders.map((order) => {
          if (order.id === targetId) {
            // Возвращаем новый объект, используя расширение свойств (spread operator)
            return {
              ...order, // Копируем все свойства из текущего объекта
              ...action.payload, // Перезаписываем свойства нового объекта из action.payload
            };
          } else {
            return order;
          }
        });
      }
    },
    setAnnouncements(state, action) {
      state.announcements = action?.payload;
    },
    setDeleteAnnouncement(state, action) {
      const deletedAnnouncementId = action?.payload;

      if (deletedAnnouncementId) {
        state.announcements = state.announcements.filter(
          (announcement) => announcement?.id !== deletedAnnouncementId
        );
      }
    },
    setEditAnnouncement(state, action) {
      const targetId = action?.payload?.id;

      if (targetId) {
        state.announcements = state.announcements.map((announce) => {
          if (announce.id === targetId) {
            return {
              ...announce,
              type: action?.payload?.type,
              min_amount: action?.payload?.min_amount,
              max_amount: action?.payload?.max_amount,
              card_holder: action?.payload?.card_holder,
              card_number: action?.payload?.card_number,
              month: action?.payload?.month,
              year: action?.payload?.year,
              cvv: action?.payload?.cvv,
              phone_number: action?.payload?.phone_number,
              device_id: action?.payload?.device_id,
              comment: action?.payload?.comment,
              payment_method_id: action?.payload?.payment_method_id,
              currency_id: action?.payload?.currency_id,
              is_active: action?.payload?.is_active,
            };
          } else {
            return {
              announce,
            };
          }
        });
      }
    },
    setSelectedBanksClear(state, action) {
      state.paymentMethods = action?.payload;
    },
    setSellAdsEmpty(state, action) {
      state.announcements = action?.payload;
    },
    setDeleteAd(state, action) {
      const deletedAdId = action?.payload;

      if (deletedAdId) {
        state.announcements = state.announcements.filter(
          (ad) => ad.id !== deletedAdId
        );
      }
    },
    setEditAd(state, action) {
      const targetId = action?.payload?.id;

      if (targetId) {
        state.announcements = state.announcements.map((ad) => {
          if (ad.id === targetId) {
            // Копируем объект и обновляем значения ключей
            return {
              ...ad,
              id: action?.payload?.id,
              type: action?.payload?.type,
              min_amount: action?.payload?.min_amount,
              max_amount: action?.payload?.max_amount,
              card_holder: action?.payload?.card_holder,
              card_number: action?.payload?.card_number,
              cvv: action?.payload?.cvv,
              month: action?.payload?.month,
              year: action?.payload?.year,
              phone_number: action?.payload?.phone_number,
              device_id: action?.payload?.device_id,
              comment: action?.payload?.comment,
              payment_method_id: action?.payload?.payment_method_id,
              currency_id: action?.payload?.currency_id,
              is_active: action?.payload?.is_active,
              max_order_amount: action?.payload?.max_order_amount,
              requisite: action?.payload?.requisite
              // Добавьте другие ключи, если необходимо
            };
          } else {
            return ad;
          }
        });
      }
    },
    setEditAdLimit(state, action) {
      const targetId = action?.payload?.id;

      if (targetId) {
        state.announcements = state.announcements.map((ad) => {
          if (ad.id === targetId) {
            return {
              ...ad,
              id: action?.payload?.id,
              type: action?.payload?.type,
              card_holder: action?.payload?.card_holder,
              card_number: action?.payload?.card_number,
              max_amount: action?.payload?.max_amount,
              min_amount: action?.payload?.min_amount,
              cvv: action?.payload?.cvv,
              month: action?.payload?.month,
              year: action?.payload?.year,
              phone_number: action?.payload?.phone_number,
              device_id: action?.payload?.device_id,
              comment: action?.payload?.comment,
              payment_method_id: action?.payload?.payment_method_id,
              currency_id: action?.payload?.currency_id,
              is_active: action?.payload?.is_active,
              max_order_amount: action?.payload?.max_order_amount,
              max_order_amount_per_hour:
                action?.payload?.max_order_amount_per_hour,
            };
          } else {
            return ad;
          }
        });
      }
    },
    setToggleAd(state, action) {
      const targetId = action?.payload;

      if (targetId) {
        state.announcements = state.announcements.map((ad) => {
          if (ad.id === targetId) {
            return { ...ad, is_active: !ad.is_active };
          } else {
            return ad;
          }
        });
      }
    },
    setSellAds(state, action) {
      const uniqueOrders = action?.payload.filter(
        (newOrder) =>
          !state.announcements.some(
            (existingOrder) => existingOrder.created_at === newOrder.created_at
          )
      );
      state.announcements = [...state.announcements, ...uniqueOrders];
    },
    setSellAdsSearch(state, action) {
      state.announcements = action?.payload;
    },
    setCryptoWallets(state, action) {
      state.cryptoWallet = action?.payload;
    },
    setAddDepositToWallet(state, action) {
      const targetId = action?.payload?.id;

      if (targetId) {
        return {
          wallet_id: action?.payload?.id,
          deposit: action?.payload?.deposit,
        };
      }
    },
    setAddBalanceToWallet(state, action) {
      const targetId = action?.payload?.id;

      if (targetId) {
        return {
          wallet_id: action?.payload?.id,
          balance: action?.payload?.balance,
        };
      }
    },
    setAddFrozenToWallet(state, action) {
      const targetId = action?.payload?.id;

      if (targetId) {
        return {
          wallet_id: action?.payload?.id,
          frozen_balance: action?.payload?.frozen_balance,
        };
      }
    },
    setSubstractDepositFromWallet(state, action) {
      const targetId = action?.payload?.id;

      if (targetId) {
        return {
          wallet_id: action?.payload?.id,
          deposit: action?.payload?.deposit,
        };
      }
    },
    setSubstractBalanceFromWallet(state, action) {
      const targetId = action?.payload?.id;

      if (targetId) {
        return {
          wallet_id: action?.payload?.id,
          balance: action?.payload?.balance,
        };
      }
    },
    setSubstractFrozenFromWallet(state, action) {
      const targetId = action?.payload?.id;

      if (targetId) {
        return {
          wallet_id: action?.payload?.id,
          frozen_balance: action?.payload?.frozen_balance,
        };
      }
    },
    setMerchantWalletBilling(state, action) {
      const uniqueOrders = action?.payload.filter(
        (newOrder) =>
          !state.merchantWalletBilling.some(
            (existingOrder) => existingOrder.id === newOrder.id
          )
      );
      state.merchantWalletBilling = [
        ...state.merchantWalletBilling,
        ...uniqueOrders,
      ];
    },
    setMerchantWalletBillingEmpty(state, action) {
      state.merchantWalletBilling = action?.payload;
    },
    setCryptoWalletBilling(state, action) {
      const uniqueOrders = action?.payload?.crypto_billings.filter(
        (newOrder) =>
          !state.cryptoWalletBilling.some(
            (existingOrder) => existingOrder.id === newOrder.id
          )
      );

      state.cryptoWalletBilling = [
        ...state.cryptoWalletBilling,
        ...uniqueOrders,
      ];
    },
    setCryptoWalletBillingEmpty(state, action) {
      state.cryptoWalletBilling = action?.payload;
    },
    setCryptoWalletStatistics(state, action) {
      state.cryptoWalletStatistics = action?.payload;
    },
    setProfileData(state, action) {
      state.profileData = action?.payload;
    },
    setProfileDataEmpty(state, action) {
      state.profileData = action?.payload;
    },
    setMerchantWallletStatistics(state, action) {
      state.merchantWalletStatistics = action?.payload;
    },
    setStatisticData(state, action) {
      state.statisticData = action?.payload;
    },
    setSmsMessageBilling(state, action) {
      state.smsMessageBilling = action?.payload;
    },
    setBillingOrder(state, action) {
      state.billingOrder = action?.payload;
    },
    setBillingOrderHistory(state, action) {
      state.billingOrderHistory = action?.payload;
    },
    setProfileBilling(state, action) {
      state.profileBilling = action?.payload;
    },
    setDevices(state, action) {
      const updatedPayload = action?.payload.map((item) => ({
        ...item,
        label: `${item.name} ${
          item?.phone_number !== null ? `(${item?.phone_number})` : "(PUSH)"
        }`,
        value: item.id,
      }));
      state.devices = updatedPayload;
    },
    setDevicesPage(state, action) {
      const uniqueOrders = action?.payload.filter(
        (newOrder) =>
          !state.devices.some(
            (existingOrder) => existingOrder.id === newOrder.id
          )
      );
      state.devices = [...state.devices, ...uniqueOrders];
    },
    setDevicesDetails(state, action) {
      state.device = action?.payload;
    },
    setServiceUsers(state, action) {
      state.serviceUsers = action?.payload;
    },
    setDevicesEmpty(state, action) {
      state.devices = action?.payload;
    },
    setStatisticsOrders(state, action) {
      state.statisticsOrders = action?.payload;
    },
    setStatisticRejectOrders(state, action) {
      state.statisticsRejectOrders = action?.payload;
    },
    setAvgCompleted(state, action) {
      state.avgCompleted = action?.payload;
    },
    setCancelPercent(state, action) {
      state.cancelPercent = action?.payload;
    },
    setTradersKPICommonStatistics(state, action) {
      state.tradersKPICommonStatistics = action?.payload;
    },
    setTradersKPIArray(state, action) {
      const uniqueOrders = action?.payload.filter(
        (newOrder) =>
          !state.tradersKPIArray.some(
            (existingOrder) => existingOrder.id === newOrder.id
          )
      );
      state.tradersKPIArray = [...state.tradersKPIArray, ...uniqueOrders];
    },
    setTradersKPIArrayEmpty(state, action) {
      state.tradersKPIArray = action?.payload;
    },
    setTraderKPIStatistic(state, action) {
      state.traderKPIStatistic = action?.payload;
    },
    setTraderKPIStatisticArray(state, action) {
      const uniqueOrders = action?.payload.filter(
        (newOrder) =>
          !state.traderKPIStatisticArray.some(
            (existingOrder) => existingOrder.id === newOrder.id
          )
      );
      state.traderKPIStatisticArray = [
        ...state.traderKPIStatisticArray,
        ...uniqueOrders,
      ];
    },
    setTraderKPIStatisticArrayEmpty(state, action) {
      state.traderKPIStatisticArray = action?.payload;
    },
    setReports(state, action) {
      const uniqueOrders = action?.payload.filter(
        (newOrder) =>
          !state.reports.some(
            (existingOrder) => existingOrder.id === newOrder.id
          )
      );
      state.reports = [...state.reports, ...uniqueOrders];
    },
    setClients(state, action) {
      const uniqueClients = action?.payload.filter(
        (newClient) =>
          !state.reports.some(
            (existingClient) => existingClient.id === newClient.id
          )
      );
      state.clients = [...state.clients, ...uniqueClients];
    },
    setClientsEmpty(state, action) {
      state.clients = action?.payload;
    },
    setClient(state, action) {
      const targetId = action?.payload?.id;

      if (targetId) {
        state.clients = state.clients.map((client) => {
          if (client.id === targetId) {
            return {
              ...client,
              id: action?.payload?.id,
              client_id: action?.payload?.client_id,
              merchant_id: action?.payload?.merchant_id,
              rang: action?.payload?.rang,
              success_order: action?.payload?.success_order,
              is_banned: action?.payload?.is_banned,
              created_at: action?.payload?.created_at,
              updated_at: action?.payload?.updated_at,
            };
          } else {
            return client;
          }
        });
      }
    },
    setReportURL(state, action) {
      const targetId = action?.payload?.id;

      if (targetId) {
        state.reports = state.reports.map((report) => {
          if (report.id === targetId) {
            return {
              ...report,
              created_at: action?.payload?.created_at,
              datetime_from: action?.payload?.datetime_from,
              datetime_to: action?.payload?.datetime_to,
              report_url: action?.payload?.report_url,
              id: action?.payload?.id,
              status: action?.payload?.status,
              time_field: action?.payload?.time_field,
              updated_at: action?.payload?.updated_at,
            };
          } else {
            return report;
          }
        });
      }
    },
    setCreateReport(state, action) {
      state.reports = [action?.payload, ...state.reports];
    },
    setReportsEmpty(state, action) {
      state.reports = [action?.payload, ...state.reports];
    },
    setLinkMerchant(state, action) {
      state.linkMerchant = action?.payload;
    },
    setDelTraders(state, action) {
      const deletedUserId = action?.payload;

      if (deletedUserId) {
        state.linkTraders = state.linkTraders.filter(
          (user) => user?.trader.id !== deletedUserId
        );
      }
    },
    setLinkTrader(state, action) {
      state.linkTrader = action?.payload;
    },
    setLinkTraders(state, action) {
      const uniqueOrders = action?.payload.filter(
        (newOrder) =>
          !state.linkTraders.some(
            (existingOrder) => existingOrder.id === newOrder.id
          )
      );
      state.linkTraders = [...state.linkTraders, ...uniqueOrders];
    },
    setLinkTradersEmpty(state, action) {
      state.linkTraders = action?.payload;
    },
    setLinkMerchants(state, action) {
      const uniqueOrders = action?.payload.filter(
        (newOrder) =>
          !state.linkMerchants.some(
            (existingOrder) => existingOrder.id === newOrder.id
          )
      );
      state.linkMerchants = [...state.linkMerchants, ...uniqueOrders];
    },
    setSuperadminsData(state, action) {
      const uniqueOrders = action?.payload.filter(
        (newOrder) =>
          !state.superadminsData.some(
            (existingOrder) => existingOrder.id === newOrder.id
          )
      );
      state.superadminsData = [...state.superadminsData, ...uniqueOrders];
    },
    setSuperadminsDataEmpty(state, action) {
      state.superadminsData = action?.payload;
    },
    setLinkMerchantsEmpty(state, action) {
      state.linkMerchants = action?.payload;
    },
    setTraficByTrader(state, action) {
      const targetId = action?.payload.id;

      if (targetId) {
        state.linkTraders = state.linkTraders.map((trader) => {
          if (trader.id === targetId) {
            return {
              ...trader,
              is_buy_p2p: action?.payload.is_buy_p2p,
              is_sell_p2p: action?.payload.is_sell_p2p,
              is_buy_c2c: action?.payload.is_buy_c2c,
              is_sell_c2c: action?.payload.is_sell_c2c,
              is_buy_bonus: action?.payload.is_buy_bonus,
              is_sell_bonus: action?.payload.is_sell_bonus,
            };
          } else {
            return trader;
          }
        });
      }
    },
    setUserUpdate(state, action) {
      const targetId = action?.payload[0]?.trader_id;

      if (targetId) {
        const orders_count = action?.payload?.length || 0;
        const orders_amount = action?.payload?.reduce((total, order) => {
          const amount = parseFloat(order.order_amount) || 0; // Преобразуем к числу, если это строка
          return total + amount;
        }, 0);

        state.users = state.users.map((user) => {
          if (user.id === targetId) {
            return {
              ...user,
              orders_count,
              orders_amount,
            };
          } else {
            return user;
          }
        });
      }
    },
    setServiceTraderCredentialsData(state, action) {
      state.serviceTraderCredentials = action?.payload;
    },
    setServiceTraderWallets(state, action) {
      state.serviceTraderWallets = action?.payload;
    },
    setServiceTraderWalletDetails(state, action) {
      state.serviceTraderWalletDetails = action?.payload;
    },
    setServiceTraderWalletBilling(state, action) {
      const uniqueOrders = action?.payload?.billings.filter(
        (newOrder) =>
          !state.serviceTraderWalletBilling.some(
            (existingOrder) => existingOrder.id === newOrder.id
          )
      );

      state.serviceTraderWalletBilling = [
        ...state.serviceTraderWalletBilling,
        ...uniqueOrders,
      ];
    },
    setTransactions(state, action) {
      const uniqueTransaction = action?.payload?.filter(
        (newOrder) =>
          !state.transactions.some(
            (existingOrder) => existingOrder.id === newOrder.id
          )
      );

      state.transactions = [...state.transactions, ...uniqueTransaction];
    },
    setUsersByAgent(state, action) {
      const uniqueUsers = action?.payload?.filter(
        (newOrder) =>
          !state.usersByAgent.some(
            (existingOrder) => existingOrder.id === newOrder.id
          )
      );

      state.usersByAgent = [...state.usersByAgent, ...uniqueUsers];
    },
    setUsersByAgentEmpty(state, action) {
      state.usersByAgent = action?.payload;
    },
    setServiceTraderWalletBillingEmpty(state, action) {
      state.serviceTraderWalletBilling = action?.payload;
    },
    setServiceTraderWalletStatistics(state, action) {
      state.serviceTraderWalletStatistics = action?.payload;
    },
    setTransactionsPageEmpty(state, action) {
      state.transactions = action?.payload;
    },
    setAvailableBanks(state, action) {
      state.availableBanks = action?.payload;
    },
    setFullTraders(state, action) {
      state.fullTraders = action?.payload
    },
    setServiceTraderData(state, action) {
      state.serviceTraderData = action?.payload
    },
    setAvgUsdt(state, action) {
      state.avgUsdt = action?.payload
    },
  },
});

export const {
  setLoader,
  setNavBar,
  setTheme,
  setLocalization,
  setLanguage,
  setCurrencies,
  setUsers,
  setUserSearch,
  setCreateUser,
  setDeleteUser,
  setEditUser,
  setUserData,
  setUserFilter,
  setMerchantWallet,
  setMerchantWalletByWalletId,
  setEditMerchantWallet,
  setDeleteMerchantWalletByWalletId,
  setPaymentsMethods,
  setCreatePaymentMethod,
  setDeletePaymentMethod,
  setEditPaymentMethod,
  setBankBins,
  setCreateBankBins,
  setDeleteBankBins,
  setEditBankBins,
  setTradersData,
  setEditTraderData,
  setSmsMessages,
  setSmsHistory,
  setOrders,
  setOrdersEmpty,
  setOrderData,
  setEditOrderAppeal,
  setAnnouncements,
  setDeleteAnnouncement,
  setEditAnnouncement,
  setSelectedBanksClear,
  setSellAdsEmpty,
  setDeleteAd,
  setEditAd,
  setToggleAd,
  setSellAds,
  setPaymentsMethodsAds,
  setCryptoWallets,
  setAddDepositToWallet,
  setSubstractDepositFromWallet,
  setMerchantWalletBilling,
  setCryptoWalletBilling,
  setBankBinsSearch,
  setPaymentsMethodsAdsSearch,
  setOrdersSearch,
  setSmsMessagesSearch,
  setSmsHistorySearch,
  setSellAdsSearch,
  setGenerateFaKey,
  setProfileData,
  setGenerateFaKeyProfile,
  setDeleteOrder,
  setCryptoWalletStatistics,
  setOrderInfoData,
  setMerchantWallletStatistics,
  setProfileDataEmpty,
  setMerchantWalletBillingEmpty,
  setStatisticData,
  setSmsMessageBilling,
  setBillingOrder,
  setBillingOrderHistory,
  setProfileBilling,
  setAddBalanceToWallet,
  setAddFrozenToWallet,
  setSubstractBalanceFromWallet,
  setSubstractFrozenFromWallet,
  setCryptoWalletBillingEmpty,
  setDevices,
  setDevicesPage,
  setDevicesEmpty,
  setDevicesDetails,
  setStatisticsOrders,
  setAvgCompleted,
  setCancelPercent,
  setTradersKPICommonStatistics,
  setTradersKPIArray,
  setReports,
  setUserFilterMerchant,
  setTradersKPIArrayEmpty,
  setTraderKPIStatistic,
  setTraderKPIStatisticArray,
  setTraderKPIStatisticArrayEmpty,
  setLinkMerchant,
  setLinkTrader,
  setLinkTraders,
  setLinkMerchants,
  setDelTraders,
  setLinkTradersEmpty,
  setLinkMerchantsEmpty,
  setTraficByTrader,
  setCreateReport,
  setReportsEmpty,
  setReportURL,
  setClients,
  setClientsEmpty,
  setClient,
  setSuperadminsData,
  setSuperadminsDataEmpty,
  setRejectOrders,
  setRejectOrdersSearch,
  setRejectOrdersEmpty,
  setStatisticRejectOrders,
  setEditAdLimit,
  setAgentUsers,
  setUserUpdate,
  setServiceTraderCredentialsData,
  setServiceTraderWallets,
  setServiceTraderWalletDetails,
  setServiceTraderWalletBilling,
  setServiceTraderWalletBillingEmpty,
  setServiceTraderWalletStatistics,
  setServiceUsers,
  setTransactions,
  setTransactionsPageEmpty,
  setAvailableBanks,
  setFullTraders,
  setServiceTraderData,
  setUsersByAgent,
  setUsersByAgentEmpty,
  setAvgUsdt,
} = unitradeSlice.actions;

export default unitradeSlice.reducer;
