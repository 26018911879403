import {setLoader, setCurrencies} from "../../Store/Slice/unitradeSlice";
import {
  useCreateCurrencyMutation, useDeletedCurrencyMutation,
  useEditedCurrencyMutation,
  useGetCurrenciesMutation
} from "../../Store/Builder/unitrade.api";
import {useDispatch} from "react-redux";
import showToast from "../Hooks/toast";
import { useNavigate } from "react-router-dom";

const ApiCurrencies = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [apiGetCurrencies] = useGetCurrenciesMutation();
  const [apiCreateCurrency] = useCreateCurrencyMutation();
  const [apiEditedCurrency] = useEditedCurrencyMutation();
  const [apiDeletedCurrency] = useDeletedCurrencyMutation();

  const getData = () => {
    dispatch(setLoader(true))
    apiGetCurrencies()
      .unwrap()
      .then((res) => {
        dispatch(setCurrencies(res))
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        // showToast(err?.data?.message ?? "Error", "error");
      });
  };

  const createCurrency = (data, closeModal) => {
    dispatch(setLoader(true))
    apiCreateCurrency(data)
      .unwrap()
      .then(() => {
        getData()
        showToast("Валюта успешно создана", "success");
        closeModal()
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      })
  }

  const editedCurrency = (data, closeModal) => {
    dispatch(setLoader(true))
    apiEditedCurrency(data)
      .unwrap()
      .then(() => {
        getData()
        showToast("Валюта успешно изменена", "success");
        closeModal()
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      })
  }

  const deletedCurrency = (data, closeModal) => {
    dispatch(setLoader(true))
    apiDeletedCurrency(data)
      .unwrap()
      .then(() => {
        getData()
        showToast("Валюта успешно удалена", "success");
        closeModal()
        dispatch(setLoader(false))
      })
      .catch((err) => {
        dispatch(setLoader(false))
        showToast(err?.data?.message ?? "Error", "error");
      })
  }

  return {
    getData,
    createCurrency,
    editedCurrency,
    deletedCurrency
  };
};

export default ApiCurrencies;


